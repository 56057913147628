/**
 * Content Extraction Module
 * Extracts relevant content from the current page for AI context
 */

import { PageContent } from '../../types';

export interface IContentExtractor {
  extractPageContent(): PageContent;
  startWatchingDOMChanges(onContentChange: (content: PageContent) => void): void;
  stopWatchingDOMChanges(): void;
}

// Extend PageContent with error property if needed
interface ExtendedPageContent extends PageContent {
  error?: string;
}

interface ContentExtractorOptions {
  maxHeadings: number;
  maxContentLength: number;
  debounceDelay: number;
  interactionDebounceDelay: number;
}

/**
 * Safe DOM operation wrapper
 * Prevents errors from crashing the app and provides a fallback
 */
function safeDOMOperation<T>(operation: () => T, fallback: T, errorContext: string): T {
  try {
    return operation();
  } catch (error) {
    console.error(`[ContentExtractor] Error in ${errorContext}:`, error);
    return fallback;
  }
}

class ContentExtractor implements IContentExtractor {
  // Configurable options
  private options: ContentExtractorOptions = {
    maxHeadings: 5,
    maxContentLength: 1500,
    debounceDelay: 1000, // Debounce delay for DOM changes in milliseconds
    interactionDebounceDelay: 500 // Shorter debounce for user interactions
  };

  private observer: MutationObserver | null = null;
  private debounceTimeout: NodeJS.Timeout | null = null;
  private interactionCleanup: (() => void)[] = [];
  
  /**
   * Extract important page content for AI context
   * @returns Structured page content
   */
  extractPageContent(): PageContent {
    return safeDOMOperation(() => {
      // Get the page title
      const pageTitle = document.title;
      
      // Get headings for structure
      const headings = Array.from(document.querySelectorAll<HTMLHeadingElement>('h1, h2, h3'))
        .map(h => h.innerText.trim())
        .slice(0, this.options.maxHeadings);
      
      // Get visible text from the viewport
      const visibleText = this.getVisibleText();
      
      // Combine all information
      return {
        title: pageTitle,
        headings: headings,
        mainContent: visibleText
      };
    }, 
    { 
      title: document.title || 'Unknown Page',
      headings: [],
      mainContent: '',
      error: 'Failed to extract page content'
    } as ExtendedPageContent, 
    'extractPageContent');
  }
  
  /**
   * Gets visible text from the current viewport
   * @returns Visible text content
   */
  private getVisibleText(): string {
    return safeDOMOperation(() => {
      const viewportHeight = window.innerHeight;
      const viewportWidth = window.innerWidth;
      
      // Get all text nodes that are visible in the viewport
      const textNodes: string[] = [];
      
      // Safety check for document.body
      if (!document.body) {
        console.warn('[ContentExtractor] Document body not available');
        return '';
      }
      
      const walker = document.createTreeWalker(
        document.body,
        NodeFilter.SHOW_TEXT,
        {
          acceptNode: function(node) {
            // Skip if no parent element or parent is hidden
            if (!node.parentElement || node.parentElement.offsetParent === null) {
              return NodeFilter.FILTER_REJECT;
            }
            
            // Skip empty text nodes
            if (!node.textContent || node.textContent.trim() === '') {
              return NodeFilter.FILTER_REJECT;
            }
            
            // Skip script and style content
            const parentTagName = node.parentElement.tagName ? 
              node.parentElement.tagName.toLowerCase() : '';
              
            if (parentTagName === 'script' || parentTagName === 'style') {
              return NodeFilter.FILTER_REJECT;
            }
            
            // Check if in viewport
            const rect = node.parentElement.getBoundingClientRect();
            if (
              rect.top < viewportHeight &&
              rect.bottom > 0 &&
              rect.left < viewportWidth &&
              rect.right > 0
            ) {
              return NodeFilter.FILTER_ACCEPT;
            }
            
            return NodeFilter.FILTER_REJECT;
          }
        } as NodeFilter
      );
      
      let currentNode: Node | null;
      while (currentNode = walker.nextNode()) {
        if (currentNode.textContent) {
          textNodes.push(currentNode.textContent.trim());
        }
      }
      
      // Join and limit the text
      return textNodes.join(' ').substring(0, this.options.maxContentLength);
    }, '', 'getVisibleText');
  }

  /**
   * Start watching for DOM changes and user interactions that affect visible content
   * @param onContentChange Callback to execute when content changes
   */
  startWatchingDOMChanges(onContentChange: (content: PageContent) => void): void {
    // Wrap the callback to protect against errors in client code
    const safeCallback = (content: PageContent) => {
      safeDOMOperation(() => {
        onContentChange(content);
      }, undefined, 'onContentChange callback');
    };
    
    safeDOMOperation(() => {
      // Clean up any existing watchers
      this.stopWatchingDOMChanges();

      // Check if MutationObserver is supported
      if (typeof MutationObserver === 'undefined') {
        console.warn('[ContentExtractor] MutationObserver not supported in this environment');
        return;
      }

      // Create a new observer instance for DOM mutations
      this.observer = new MutationObserver((mutations) => {
        // Check if mutations affect visible content
        const hasVisibleChanges = mutations.some(mutation => {
          return safeDOMOperation(() => {
            // Check if the mutation is in a visible element
            const target = mutation.target as HTMLElement;
            if (!target || target.offsetParent === null) {
              return false;
            }

            // Ignore changes in script and style elements
            const tagName = target.tagName ? target.tagName.toLowerCase() : '';
            if (tagName === 'script' || tagName === 'style') {
              return false;
            }

            // Check if the mutation is caused by user interaction
            const isUserInteraction = this.isUserInitiatedChange(target);
            if (!isUserInteraction) {
              return false;
            }

            // Check if the mutation affects text content or structure
            return mutation.type === 'characterData' || 
                   mutation.type === 'childList' ||
                   (mutation.type === 'attributes' && 
                    ['style', 'class'].includes(mutation.attributeName || ''));
          }, false, 'mutation evaluation');
        });

        if (hasVisibleChanges) {
          // Debounce the callback to avoid too frequent updates
          if (this.debounceTimeout) {
            clearTimeout(this.debounceTimeout);
          }
          this.debounceTimeout = setTimeout(() => {
            const content = this.extractPageContent();
            safeCallback(content);
          }, this.options.debounceDelay);
        }
      });

      // Make sure document.body exists before observing
      if (document.body) {
        // Start observing the entire document for DOM changes
        this.observer.observe(document.body, {
          childList: true,
          subtree: true,
          characterData: true,
          attributes: true,
          attributeFilter: ['style', 'class']
        });

        // Set up user interaction monitoring
        this.setupInteractionMonitoring(safeCallback);
      } else {
        console.warn('[ContentExtractor] Document body not available for ContentExtractor');
      }
    }, undefined, 'startWatchingDOMChanges');
  }

  /**
   * Set up monitoring for user interactions
   * @param onContentChange Callback to execute when content changes
   */
  private setupInteractionMonitoring(onContentChange: (content: PageContent) => void): void {
    safeDOMOperation(() => {
      let interactionTimeout: NodeJS.Timeout | null = null;

      // Helper function to debounce interaction updates
      const handleInteraction = () => {
        if (interactionTimeout) {
          clearTimeout(interactionTimeout);
        }
        interactionTimeout = setTimeout(() => {
          const content = this.extractPageContent();
          onContentChange(content);
        }, this.options.interactionDebounceDelay);
      };

      // Check if addEventListener is supported
      if (typeof document.addEventListener !== 'function') {
        console.warn('[ContentExtractor] addEventListener not supported in this environment');
        return;
      }

      // Monitor clicks on interactive elements
      const clickHandler = (event: MouseEvent) => {
        safeDOMOperation(() => {
          const target = event.target as HTMLElement;
          if (target && this.isInteractiveElement(target)) {
            handleInteraction();
          }
        }, undefined, 'click handler');
      };

      // Monitor keyboard input on form elements
      const inputHandler = (event: Event) => {
        safeDOMOperation(() => {
          const target = event.target as HTMLElement;
          if (target && this.isFormElement(target)) {
            handleInteraction();
          }
        }, undefined, 'input handler');
      };

      // Monitor form submissions
      const formSubmitHandler = () => {
        safeDOMOperation(() => {
          handleInteraction();
        }, undefined, 'form submit handler');
      };

      // Add event listeners
      document.addEventListener('click', clickHandler, true);
      document.addEventListener('input', inputHandler, true);
      document.addEventListener('change', inputHandler, true);
      document.addEventListener('submit', formSubmitHandler, true);

      // Store cleanup functions
      this.interactionCleanup.push(
        () => {
          document.removeEventListener('click', clickHandler, true);
          document.removeEventListener('input', inputHandler, true);
          document.removeEventListener('change', inputHandler, true);
          document.removeEventListener('submit', formSubmitHandler, true);
          if (interactionTimeout) {
            clearTimeout(interactionTimeout);
          }
        }
      );
    }, undefined, 'setupInteractionMonitoring');
  }

  /**
   * Check if a DOM change was initiated by user interaction
   * @param element The element that changed
   * @returns boolean indicating if change was user-initiated
   */
  private isUserInitiatedChange(element: HTMLElement): boolean {
    return safeDOMOperation(() => {
      if (!element) return false;
      
      // Check if the element is actually an Element (not a text node or other node type)
      // Element nodes have methods like matches, closest, and hasAttribute
      const isElement = element.nodeType === 1;
      if (!isElement) return false;

      // Check if the element supports matches method
      const supportsMatches = element.matches !== undefined && typeof element.matches === 'function';
      const supportsClosest = element.closest !== undefined && typeof element.closest === 'function';
      const supportsGetAttribute = typeof element.getAttribute === 'function';
      const supportsHasAttribute = typeof element.hasAttribute === 'function';
      
      // Check if element is or contains an input/textarea/contenteditable
      const isInputElement = 
        (supportsMatches && element.matches('input, textarea, [contenteditable]')) ||
        (supportsClosest && !!element.closest('input, textarea, [contenteditable]'));

      // Check if element was recently interacted with
      const wasRecentlyClicked = 
        (supportsMatches && element.matches(':active, :focus')) ||
        (supportsClosest && !!element.closest(':active, :focus'));

      // Check if element is part of a form
      const isFormElement = supportsMatches && element.matches('form, form *');

      // Check if element has user interaction related attributes (only if hasAttribute is supported)
      let hasInteractionAttrs = false;
      if (supportsHasAttribute) {
        hasInteractionAttrs = element.hasAttribute('onclick') || 
                            element.hasAttribute('onchange') ||
                            element.hasAttribute('onsubmit') ||
                            element.hasAttribute('onkeyup') ||
                            element.hasAttribute('onkeydown');
      } else if (supportsGetAttribute) {
        // Fallback to using getAttribute if hasAttribute is not available
        hasInteractionAttrs = !!element.getAttribute('onclick') || 
                            !!element.getAttribute('onchange') ||
                            !!element.getAttribute('onsubmit') ||
                            !!element.getAttribute('onkeyup') ||
                            !!element.getAttribute('onkeydown');
      }

      return isInputElement || wasRecentlyClicked || isFormElement || hasInteractionAttrs;
    }, false, 'isUserInitiatedChange');
  }

  /**
   * Check if an element is interactive (clickable)
   */
  private isInteractiveElement(element: HTMLElement): boolean {
    return safeDOMOperation(() => {
      if (!element) return false;
      
      // Verify element is an actual Element node
      const isElement = element.nodeType === 1;
      if (!isElement) return false;

      const supportsGetAttribute = typeof element.getAttribute === 'function';
      const interactiveTags = ['a', 'button', 'select', 'input', 'textarea'];
      const tagName = element.tagName ? element.tagName.toLowerCase() : '';
      
      // Check if the element supports matches method for querySelector-based checks
      const supportsMatches = element.matches !== undefined && typeof element.matches === 'function';
      
      // Safe checks for attribute accessors
      const getRole = () => supportsGetAttribute ? element.getAttribute('role') : null;
      const getTabIndex = () => supportsGetAttribute ? element.getAttribute('tabindex') : null;
      
      return interactiveTags.includes(tagName) ||
             getRole() === 'button' ||
             getRole() === 'link' ||
             element.onclick !== null ||
             getTabIndex() === '0' ||
             (window.getComputedStyle && window.getComputedStyle(element).cursor === 'pointer');
    }, false, 'isInteractiveElement');
  }

  /**
   * Check if an element is a form element
   */
  private isFormElement(element: HTMLElement): boolean {
    return safeDOMOperation(() => {
      if (!element) return false;
      
      // Verify element is an actual Element node
      const isElement = element.nodeType === 1;
      if (!isElement) return false;
      
      const supportsGetAttribute = typeof element.getAttribute === 'function';
      const formElements = ['input', 'textarea', 'select'];
      const tagName = element.tagName ? element.tagName.toLowerCase() : '';
      
      const isContentEditable = supportsGetAttribute ? 
        element.getAttribute('contenteditable') === 'true' : false;
      
      return formElements.includes(tagName) || isContentEditable;
    }, false, 'isFormElement');
  }

  /**
   * Stop watching for DOM changes and user interactions
   */
  stopWatchingDOMChanges(): void {
    safeDOMOperation(() => {
      // Stop DOM mutation observer
      if (this.observer) {
        this.observer.disconnect();
        this.observer = null;
      }

      // Clear any pending debounce timeout
      if (this.debounceTimeout) {
        clearTimeout(this.debounceTimeout);
        this.debounceTimeout = null;
      }

      // Clean up interaction monitors
      this.interactionCleanup.forEach(cleanup => {
        try {
          cleanup();
        } catch (error) {
          console.error('[ContentExtractor] Error in cleanup function:', error);
        }
      });
      this.interactionCleanup = [];
    }, undefined, 'stopWatchingDOMChanges');
  }
}

export default ContentExtractor; 