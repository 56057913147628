/**
 * SDK Platform Adapter
 * Provides platform-specific functionality for JavaScript SDK
 */

import { IPlatformAdapter } from './IPlatformAdapter';
import logger from '../utils/logger';

/**
 * Safe operation wrapper to prevent any SDK function from crashing
 */
function safeOperation<T>(operation: () => T, fallback: T, context: string): T {
  try {
    return operation();
  } catch (error) {
    logger.debugError(`Error in ${context}:`, error);
    return fallback;
  }
}

class SDKAdapter implements IPlatformAdapter {
  private currentDomain: string = '';
  private currentPath: string = '';
  private iconBasePath: string = ''; // Will be auto-detected
  
  /**
   * Create a new SDK adapter
   */
  constructor() {
    safeOperation(() => {
      // Set initial domain and path
      this.currentDomain = window.location.hostname.replace('www.', '');
      this.currentPath = window.location.pathname;
      
      // Auto-detect the icon path based on the SDK script location
      this.autoDetectIconPath();
    }, undefined, 'SDKAdapter constructor');
  }
  
  /**
   * Auto-detect the icon path based on the SDK script location
   * This ensures icons are found regardless of how the SDK is included
   */
  private autoDetectIconPath(): void {
    safeOperation(() => {
      // Default to icons/ relative to the current page
      this.iconBasePath = 'icons/';
      
      // Try to find the SDK script to determine where it's loaded from
      const scripts = document.querySelectorAll('script');
      for (let i = 0; i < scripts.length; i++) {
        const script = scripts[i];
        const src = script.src || '';
        
        // Look for the voice assistant SDK script
        if (src && (
          src.includes('voice-assistant.js') || 
          src.includes('voice-assistant.browser.js') || 
          src.includes('voice-assistant.browser.min.js')
        )) {
          // Extract the base URL of the script
          const scriptUrl = new URL(src);
          const baseUrl = `${scriptUrl.protocol}//${scriptUrl.host}${scriptUrl.pathname.substring(0, scriptUrl.pathname.lastIndexOf('/') + 1)}`;
          
          // Set the icon path relative to the script location
          this.iconBasePath = `${baseUrl}icons/`;
          logger.log('Auto-detected icon path:', this.iconBasePath);
          return;
        }
      }
    }, undefined, 'autoDetectIconPath');
  }
  
  /**
   * Initialize the adapter
   */
  async init(): Promise<void> {
    return safeOperation(async () => {
      logger.log('SDKAdapter initialized');
    }, undefined, 'init');
  }
  
  /**
   * Get a value from storage
   * @param key The key to retrieve
   */
  async getValue(key: string): Promise<string | null> {
    return safeOperation(() => {
      return localStorage.getItem(key);
    }, null, `getValue(${key})`);
  }
  
  /**
   * Set a value in storage
   * @param key The key to set
   * @param value The value to store
   */
  async setValue(key: string, value: string): Promise<void> {
    return safeOperation(() => {
      localStorage.setItem(key, value);
    }, undefined, `setValue(${key})`);
  }
  
  /**
   * Watch for navigation changes
   * @param callback Function to call when navigation occurs
   */
  watchNavigation(callback: (url: string) => void): () => void {
    return safeOperation(() => {
      // Create a safe version of the callback that won't crash
      const safeCallback = (url: string) => {
        safeOperation(() => {
          callback(url);
        }, undefined, 'navigation callback');
      };
      
      // Set up interval to check for URL changes (for SPA support)
      const interval = setInterval(() => {
        safeOperation(() => {
          const newPath = window.location.pathname;
          if (newPath !== this.currentPath) {
            this.currentPath = newPath;
            safeCallback(window.location.href);
          }
        }, undefined, 'navigation polling');
      }, 500);
      
      // Set up standard navigation events
      const popstateHandler = () => {
        safeOperation(() => {
          safeCallback(window.location.href);
        }, undefined, 'popstate handler');
      };
      
      const hashchangeHandler = () => {
        safeOperation(() => {
          safeCallback(window.location.href);
        }, undefined, 'hashchange handler');
      };
      
      window.addEventListener('popstate', popstateHandler);
      window.addEventListener('hashchange', hashchangeHandler);
      
      // Return cleanup function
      return () => {
        safeOperation(() => {
          clearInterval(interval);
          window.removeEventListener('popstate', popstateHandler);
          window.removeEventListener('hashchange', hashchangeHandler);
        }, undefined, 'navigation cleanup');
      };
    }, () => {
      // Return a no-op cleanup function as fallback
      return () => {};
    }, 'watchNavigation');
  }

  /**
   * Get current domain/path information
   */
  getCurrentPageInfo(): { domain: string; path: string } {
    return safeOperation(() => {
      return {
        domain: this.currentDomain,
        path: this.currentPath
      };
    }, { domain: '', path: '' }, 'getCurrentPageInfo');
  }

  /**
   * Get the icon URL from resources
   * @param iconName Name of the icon file
   */
  getIconUrl(iconName: string): string {
    return safeOperation(() => {
      return `${this.iconBasePath}${iconName}`;
    }, 'icons/fallback.png', 'getIconUrl');
  }
}

export default SDKAdapter; 