/**
 * Storage Manager
 * Provides a data access layer for managing persistent storage
 */

import { IPlatformAdapter } from '../../platform/IPlatformAdapter';
import { VoiceAssistantState } from '../../types';
import { IFirebaseService } from './FirebaseService';

// Message types for conversation history
export interface ConversationMessage {
  role: 'user' | 'assistant' | 'system';
  content: string;
  timestamp: number;
}

export interface IStorageManager {
  getAssistantState(): Promise<Partial<VoiceAssistantState>>;
  saveAssistantState(state: Partial<VoiceAssistantState>): Promise<void>;
  
  // User ID methods
  getUserId(): Promise<string>;
  
  // Conversation history methods
  getConversationHistory(): Promise<ConversationMessage[]>;
  addMessageToHistory(message: ConversationMessage): Promise<void>;
  updateSystemMessage(content: string): Promise<void>;
  clearConversationHistory(summarizer?: (messages: ConversationMessage[]) => Promise<string>): Promise<void>;
  
  // Past conversation history methods
  storePastConversation(sessionTime: number): Promise<void>;
  getPastConversations(): Promise<Array<{timestamp: number, messages: ConversationMessage[]}>>;
  
  // Conversation summary methods
  storePastConversationSummary(summary: string, sessionTime: number): Promise<void>;
  getPastConversationSummaries(): Promise<Array<{timestamp: number, summary: string}>>;
}

class StorageManager implements IStorageManager {
  private maxHistorySize: number;
  private firebaseService: IFirebaseService | null = null;
  private recordConversations: boolean = false;
  private userId: string | null = null;

  constructor(
    private platformAdapter: IPlatformAdapter,
    maxHistorySize: number = 20,
    firebaseService?: IFirebaseService,
    recordConversations?: boolean,
    userId?: string
  ) {
    this.maxHistorySize = maxHistorySize;
    this.firebaseService = firebaseService || null;
    this.recordConversations = recordConversations || false;
    this.userId = userId || null;
    
    // If a user ID was provided in the constructor, log it to Firebase
    if (this.userId) {
      console.log('Using provided user ID:', this.userId);
      // Log the user ID to Firebase if available
      if (this.firebaseService) {
        this.firebaseService.logUserSession(this.userId)
          .catch(error => {
            console.error('Error logging user ID to Firebase:', error);
          });
      }
    } else {
      // Only call getUserId if no userId was provided
      this.getUserId().catch(error => {
        console.error('Error initializing user ID in StorageManager constructor:', error);
      });
    }
  }

  /**
   * Get the voice assistant state from Firebase
   */
  async getAssistantState(): Promise<Partial<VoiceAssistantState>> {
    try {
      const userId = await this.getUserId();
      
      if (this.firebaseService) {
        try {
          const firebaseState = await this.firebaseService.getAssistantState(userId);
          console.log('Retrieved assistant state from Firebase');
          return firebaseState;
        } catch (error) {
          console.error('Failed to retrieve state from Firebase:', error);
        }
      } else {
        console.warn('Firebase service not available');
      }
      
      // Return minimal state with just userId if Firebase is unavailable
      return { userId };
    } catch (error) {
      console.error('Error retrieving assistant state:', error);
      return {};
    }
  }

  /**
   * Save the voice assistant state to Firebase
   * @param state The state to save
   */
  async saveAssistantState(state: Partial<VoiceAssistantState>): Promise<void> {
    try {
      const userId = await this.getUserId();
      
      if (this.firebaseService) {
        await this.firebaseService.saveAssistantState(userId, state);
        console.log('Saved assistant state to Firebase');
      } else {
        console.error('Firebase service not available, unable to save state');
        throw new Error('Firebase service not available');
      }
    } catch (error) {
      console.error('Error saving assistant state:', error);
      throw error;
    }
  }

  /**
   * Get or generate a unique user ID
   * @returns Unique user ID (GUID)
   */
  async getUserId(): Promise<string> {
    try {
      // Return cached user ID if we have it already
      if (this.userId) {
        return this.userId;
      }
      
      // Generate a new UUID
      this.userId = this.generateUUID();
      
      // Log the new user ID to Firebase if available
      if (this.firebaseService) {
        try {
          await this.firebaseService.logUserSession(this.userId);
        } catch (error) {
          console.error('Error logging new user ID to Firebase:', error);
        }
      }
      
      return this.userId;
    } catch (error) {
      console.error('Error getting/generating user ID:', error);
      const tempId = this.generateUUID();
      this.userId = tempId;
      return tempId;
    }
  }

  /**
   * Generate a UUID v4 (simplified implementation)
   * @returns A UUID v4 string
   */
  private generateUUID(): string {
    // Implementation of UUID v4 without external dependency
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
      const r = Math.random() * 16 | 0;
      const v = c === 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
    });
  }

  /**
   * Get conversation history from Firebase
   * @returns Array of conversation messages
   */
  async getConversationHistory(): Promise<ConversationMessage[]> {
    try {
      const userId = await this.getUserId();
      
      if (this.firebaseService) {
        try {
          const firebaseHistory = await this.firebaseService.getConversationHistory(userId);
          console.log('Retrieved conversation history from Firebase');
          return firebaseHistory;
        } catch (error) {
          console.error('Failed to retrieve history from Firebase:', error);
        }
      } else {
        console.warn('Firebase service not available');
      }
      
      // Return empty array if Firebase is unavailable
      return [];
    } catch (error) {
      console.error('Error retrieving conversation history:', error);
      return [];
    }
  }

  /**
   * Add a message to the conversation history in Firebase
   * @param message The message to add
   */
  async addMessageToHistory(message: ConversationMessage): Promise<void> {
    try {
      const userId = await this.getUserId();
      
      if (this.firebaseService) {
        await this.firebaseService.addMessageToHistory(userId, message);
        console.log('Added message to Firebase conversation history');
        
        // Log the message for analytics if recording is enabled
        if (this.recordConversations) {
          await this.firebaseService.logConversationMessage(userId, message);
        }
      } else {
        console.error('Firebase service not available, unable to add message to history');
        throw new Error('Firebase service not available');
      }
    } catch (error) {
      console.error('Error adding message to history:', error);
      throw error;
    }
  }

  /**
   * Update the system message at the beginning of the conversation history
   * @param content The new system message content
   */
  async updateSystemMessage(content: string): Promise<void> {
    try {
      const userId = await this.getUserId();
      
      if (this.firebaseService) {
        await this.firebaseService.updateSystemMessage(userId, content);
        console.log('Updated system message in Firebase');
      } else {
        console.error('Firebase service not available, unable to update system message');
        throw new Error('Firebase service not available');
      }
    } catch (error) {
      console.error('Error updating system message:', error);
      throw error;
    }
  }

  /**
   * Clear the conversation history
   * First stores the current conversation as a past conversation
   * If a summarizer is provided, also generates and stores a summary
   * @param summarizer Optional function to generate a summary of the conversation
   */
  async clearConversationHistory(summarizer?: (messages: ConversationMessage[]) => Promise<string>): Promise<void> {
    try {
      const userId = await this.getUserId();
      
      // Get the current conversation history before clearing
      
      let currentHistory = await this.getConversationHistory();
      // remove prompt
      currentHistory.shift()
      
      // Store current conversation with current timestamp before clearing
      const sessionTime = Date.now();
      await this.storePastConversation(sessionTime);
      
      // Generate and store summary if summarizer is provided
      if (summarizer && currentHistory.length > 0) {
        try {
          const summary = await summarizer(currentHistory);
          if (summary) {
            await this.storePastConversationSummary(summary, sessionTime);
            console.log('Generated and stored conversation summary');
          }
        } catch (summaryError) {
          console.error('Error generating conversation summary:', summaryError);
          // Continue with clearing even if summary fails
        }
      }
      
      if (this.firebaseService) {
        await this.firebaseService.clearConversationHistory(userId);
        console.log('Cleared conversation history in Firebase');
      } else {
        console.error('Firebase service not available, unable to clear conversation history');
        throw new Error('Firebase service not available');
      }
    } catch (error) {
      console.error('Error clearing conversation history:', error);
      throw error;
    }
  }

  /**
   * Store a past conversation in Firebase
   * @param sessionTime The timestamp of the conversation
   */
  async storePastConversation(sessionTime: number): Promise<void> {
    try {
      const userId = await this.getUserId();
      
      // Get current conversation history before clearing
      const currentHistory = await this.getConversationHistory();
      
      // remove prompt 
      currentHistory.shift()
      if (this.firebaseService) {
        await this.firebaseService.storePastConversation(userId, currentHistory, sessionTime);
        console.log('Stored past conversation in Firebase');
      } else {
        console.error('Firebase service not available, unable to store past conversation');
        throw new Error('Firebase service not available');
      }
    } catch (error) {
      console.error('Error storing past conversation:', error);
      throw error;
    }
  }

  /**
   * Get the past conversation histories from Firebase
   * @returns Array of past conversations with timestamps
   */
  async getPastConversations(): Promise<Array<{timestamp: number, messages: ConversationMessage[]}>> {
    try {
      const userId = await this.getUserId();
      
      if (this.firebaseService) {
        const pastConversations = await this.firebaseService.getPastConversations(userId);
        console.log('Retrieved past conversations from Firebase');
        return pastConversations;
      } else {
        console.error('Firebase service not available, unable to retrieve past conversations');
        return [];
      }
    } catch (error) {
      console.error('Error retrieving past conversations:', error);
      return [];
    }
  }

  /**
   * Store a past conversation summary in Firebase
   * @param summary The summary to store
   * @param sessionTime The timestamp of the conversation
   */
  async storePastConversationSummary(summary: string, sessionTime: number): Promise<void> {
    try {
      const userId = await this.getUserId();
      
      if (this.firebaseService) {
        await this.firebaseService.storePastConversationSummary(userId, summary, sessionTime);
        console.log('Stored past conversation summary in Firebase');
      } else {
        console.error('Firebase service not available, unable to store past conversation summary');
        throw new Error('Firebase service not available');
      }
    } catch (error) {
      console.error('Error storing past conversation summary:', error);
      throw error;
    }
  }

  /**
   * Get the past conversation summaries from Firebase
   * @returns Array of past conversation summaries with timestamps
   */
  async getPastConversationSummaries(): Promise<Array<{timestamp: number, summary: string}>> {
    try {
      const userId = await this.getUserId();
      
      if (this.firebaseService) {
        const pastSummaries = await this.firebaseService.getPastConversationSummaries(userId);
        console.log('Retrieved past conversation summaries from Firebase');
        return pastSummaries;
      } else {
        console.error('Firebase service not available, unable to retrieve past conversation summaries');
        return [];
      }
    } catch (error) {
      console.error('Error retrieving past conversation summaries:', error);
      return [];
    }
  }
}

export default StorageManager; 