/**
 * Minimal UI Adapter
 * Basic UI implementation that can be used by both Chrome and SDK
 */

import { IUIAdapter } from './IUIAdapter';

class MinimalUIAdapter implements IUIAdapter {
  private container!: HTMLDivElement;
  private voiceButton!: HTMLDivElement;
  private audioElement!: HTMLAudioElement;
  private clickHandler: (() => void) | null = null;
  private isConnecting: boolean = false;
  
  // New additions
  private visualizerContainer!: HTMLDivElement;
  private outerCircle!: HTMLDivElement;
  private innerCircle!: HTMLDivElement;
  private textContainer!: HTMLDivElement;
  private titleText!: HTMLDivElement;
  private actionButton!: HTMLDivElement;
  private animationFrameId: number | null = null;
  private isListening: boolean = false;
  
  // Audio analysis properties
  private audioContext: AudioContext | null = null;
  private audioAnalyser: AnalyserNode | null = null;
  private audioSource: MediaElementAudioSourceNode | null = null;
  private dataArray: Uint8Array | null = null;

  /**
   * Create a new UI adapter
   * @param containerId Optional ID of container to use instead of creating one
   * @param buttonPosition Position of the button on screen
   */
  constructor(
    private containerId?: string,
    private buttonPosition: { bottom?: string; right?: string; top?: string; left?: string } = { bottom: '20px', right: '20px' }
  ) {}
  
  /**
   * Initialize UI components
   */
  async init(): Promise<void> {
    // Create container
    this.container = this.createContainer();
    
    // Create voice button (the entire UI now)
    this.voiceButton = this.createVoiceButton();
    this.container.appendChild(this.voiceButton);
    
    // Position button
    this.positionButton(this.buttonPosition);
    
    // Create audio element
    this.audioElement = document.createElement('audio');
    this.audioElement.volume = 0.8; // Default volume
    document.body.appendChild(this.audioElement);
  }
  
  /**
   * Create container element
   */
  private createContainer(): HTMLDivElement {
    // Use existing container if specified and found
    if (this.containerId) {
      const existingContainer = document.getElementById(this.containerId);
      if (existingContainer && existingContainer instanceof HTMLDivElement) {
        return existingContainer;
      }
    }
    
    // Create new container
    const container = document.createElement('div');
    container.id = 'voice-assistant-container';
    container.style.position = 'fixed';
    container.style.zIndex = '999999';
    document.body.appendChild(container);
    return container;
  }
  
  /**
   * Create voice button element with tooltip
   */
  private createVoiceButton(): HTMLDivElement {
    // Create main button container
    const button = document.createElement('div');
    
    // Apply base button styles - now for the entire UI card
    Object.assign(button.style, {
      width: '320px',
      height: '80px',
      backgroundColor: '#ffffff',
      borderRadius: '16px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      cursor: 'pointer',
      boxShadow: '0 4px 12px rgba(0, 0, 0, 0.15)',
      transition: 'all 0.3s ease',
      padding: '10px 20px',
      opacity: '0.95'
    });

    // Left part - Visualizer (20% of width)
    this.visualizerContainer = document.createElement('div');
    Object.assign(this.visualizerContainer.style, {
      width: '60px', 
      height: '60px',
      position: 'relative',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    });
    
    // Create outer circle
    this.outerCircle = document.createElement('div');
    Object.assign(this.outerCircle.style, {
      width: '54px',
      height: '54px',
      borderRadius: '50%',
      backgroundColor: '#E5E5E5',
      position: 'absolute',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      transition: 'transform 0.3s ease'
    });
    
    // Create inner circle with CD-like gradient
    this.innerCircle = document.createElement('div');
    Object.assign(this.innerCircle.style, {
      width: '40px',
      height: '40px',
      borderRadius: '50%',
      position: 'absolute',
      background: 'radial-gradient(circle, #1E90FF, #4169E1, #0000CD)',
      boxShadow: 'inset 0 0 15px rgba(255, 255, 255, 0.6)'
    });
    
    // Right part - Text and Button
    this.textContainer = document.createElement('div');
    Object.assign(this.textContainer.style, {
      flex: '1',
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      marginLeft: '15px'
    });
    
    // Title text
    this.titleText = document.createElement('div');
    Object.assign(this.titleText.style, {
      fontSize: '14px',
      fontWeight: '500',
      color: '#333',
      marginBottom: '8px'
    });
    this.titleText.textContent = 'Talk to our AI about Time Doctor';
    
    // Action button
    this.actionButton = document.createElement('div');
    Object.assign(this.actionButton.style, {
      display: 'flex',
      alignItems: 'center',
      padding: '6px 12px',
      borderRadius: '8px',
      border: '1px solid #E0E0E0',
      fontSize: '13px',
      fontWeight: '500',
      color: '#555',
      width: 'fit-content'
    });
    
    // Phone icon
    const phoneIcon = document.createElement('div');
    Object.assign(phoneIcon.style, {
      marginRight: '6px',
      width: '14px',
      height: '14px',
      backgroundImage: 'url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns=\'http://www.w3.org/2000/svg\' width=\'14\' height=\'14\' viewBox=\'0 0 24 24\' fill=\'none\' stroke=\'%23555\' stroke-width=\'2\' stroke-linecap=\'round\' stroke-linejoin=\'round\'%3e%3cpath d=\'M22 16.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z\'%3e%3c/path%3e%3c/svg%3e")',
      backgroundSize: 'contain',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center'
    });
    
    this.actionButton.appendChild(phoneIcon);
    this.actionButton.appendChild(document.createTextNode('Start a call'));
    
    // Assemble the UI
    this.visualizerContainer.appendChild(this.outerCircle);
    this.outerCircle.appendChild(this.innerCircle);
    
    this.textContainer.appendChild(this.titleText);
    this.textContainer.appendChild(this.actionButton);
    
    button.appendChild(this.visualizerContainer);
    button.appendChild(this.textContainer);
    
    // Add click event
    button.addEventListener('click', () => {
      if (this.clickHandler) {
        this.clickHandler();
      }
    });
    
    return button;
  }
  
  /**
   * Set the image source for the button
   * @param src Image source URL
   */
  setButtonImageSrc(src: string): void {
    // Method kept for backwards compatibility
    // We now use CSS for icons
  }
  
  /**
   * Show the voice assistant UI
   */
  showUI(): void {
    if (this.container) {
      this.container.style.display = 'block';
    }
  }
  
  /**
   * Hide the voice assistant UI
   */
  hideUI(): void {
    if (this.container) {
      this.container.style.display = 'none';
    }
  }
  
  /**
   * Update UI to reflect connection status
   * @param status Current connection status
   */
  updateStatus(status: { isConnected: boolean; isConnecting: boolean }): void {
    if (!this.voiceButton) return;
    
    this.isConnecting = status.isConnecting;
    
    if (status.isConnecting) {
      // State 2: Connecting
      this.titleText.textContent = 'Connecting';
      
      // Update action button
      this.actionButton.innerHTML = '';
      const crossedPhoneIcon = document.createElement('div');
      Object.assign(crossedPhoneIcon.style, {
        marginRight: '6px',
        width: '14px',
        height: '14px',
        backgroundImage: 'url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns=\'http://www.w3.org/2000/svg\' width=\'14\' height=\'14\' viewBox=\'0 0 24 24\' fill=\'none\' stroke=\'%23555\' stroke-width=\'2\' stroke-linecap=\'round\' stroke-linejoin=\'round\'%3e%3cpath d=\'M10.68 13.31a16 16 0 0 0 3.41 2.6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.42 19.42 0 0 1-3.33-2.67m-2.67-3.34a19.79 19.79 0 0 1-3.07-8.63A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91\'%3e%3c/path%3e%3cline x1=\'23\' y1=\'1\' x2=\'1\' y2=\'23\'%3e%3c/line%3e%3c/svg%3e")',
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center'
      });
      this.actionButton.appendChild(crossedPhoneIcon);
      this.actionButton.appendChild(document.createTextNode('End call'));
      
      this.startPulsingAnimation();
    } else if (status.isConnected) {
      // State 3: Connected/Listening
      this.titleText.textContent = 'Listening';
      this.isListening = true;
      
      // Update action button to End call
      this.actionButton.innerHTML = '';
      const phoneEndIcon = document.createElement('div');
      Object.assign(phoneEndIcon.style, {
        marginRight: '6px',
        width: '14px',
        height: '14px',
        backgroundImage: 'url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns=\'http://www.w3.org/2000/svg\' width=\'14\' height=\'14\' viewBox=\'0 0 24 24\' fill=\'none\' stroke=\'%23555\' stroke-width=\'2\' stroke-linecap=\'round\' stroke-linejoin=\'round\'%3e%3cpath d=\'M10.68 13.31a16 16 0 0 0 3.41 2.6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.42 19.42 0 0 1-3.33-2.67m-2.67-3.34a19.79 19.79 0 0 1-3.07-8.63A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91\'%3e%3c/path%3e%3cline x1=\'23\' y1=\'1\' x2=\'1\' y2=\'23\'%3e%3c/line%3e%3c/svg%3e")',
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center'
      });
      this.actionButton.appendChild(phoneEndIcon);
      this.actionButton.appendChild(document.createTextNode('End call'));
      
      // Start speech animation
      this.startSpeechAnimation();
      
      this.stopPulsingAnimation();
    } else {
      // State 1: Normal state
      this.titleText.textContent = 'Talk to our AI about Time Doctor';
      this.isListening = false;
      
      // Reset action button
      this.actionButton.innerHTML = '';
      const phoneIcon = document.createElement('div');
      Object.assign(phoneIcon.style, {
        marginRight: '6px',
        width: '14px',
        height: '14px',
        backgroundImage: 'url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns=\'http://www.w3.org/2000/svg\' width=\'14\' height=\'14\' viewBox=\'0 0 24 24\' fill=\'none\' stroke=\'%23555\' stroke-width=\'2\' stroke-linecap=\'round\' stroke-linejoin=\'round\'%3e%3cpath d=\'M22 16.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z\'%3e%3c/path%3e%3c/svg%3e")',
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center'
      });
      this.actionButton.appendChild(phoneIcon);
      this.actionButton.appendChild(document.createTextNode('Start a call'));
      
      this.stopPulsingAnimation();
      this.stopSpeechAnimation();
    }
  }
  
  /**
   * Start pulsing animation effect for the button
   */
  private startPulsingAnimation(): void {
    if (!this.voiceButton) return;
    
    // Clear any existing animation
    this.stopPulsingAnimation();
    
    let growing = true;
    const pulseAnimation = () => {
      if (!this.isConnecting || !this.voiceButton) return;
      
      const currentScale = growing ? 1.05 : 1.0;
      
      this.voiceButton.style.transform = `scale(${currentScale})`;
      
      growing = !growing;
      
      requestAnimationFrame(() => {
        if (this.isConnecting) {
          setTimeout(pulseAnimation, 750);
        }
      });
    };
    
    pulseAnimation();
  }
  
  /**
   * Stop pulsing animation effect
   */
  private stopPulsingAnimation(): void {
    if (!this.voiceButton) return;
    
    this.voiceButton.style.transform = 'scale(1)';
  }

  /**
   * Start speech animation that responds to audio
   */
  private startSpeechAnimation(): void {
    if (!this.outerCircle || !this.innerCircle) return;
    
    // Stop any existing animation
    this.stopSpeechAnimation();
    
    let counter = 0;
    
    // Reduced smoothing for more immediate response
    const smoothingFactor = 0.3; // Much less smoothing (was 0.7)
    let smoothedAmplitude = 0.1; // Small default amplitude
    
    // Constants for visualization - initialize with base values
    const circleValues = [1.0, 1.0]; // Start at normal size
    const targetValues = [1.0, 1.0]; // Start at normal size
    
    // Animation function that directly animates the existing circles
    const animate = () => {
      // Smaller minimum value to show more audio impact
      let rawAmplitude = 0.05; // Reduced minimum amplitude
      
      // Try to get audio data from analyzer if available
      if (this.audioAnalyser && this.dataArray) {
        this.audioAnalyser.getByteTimeDomainData(this.dataArray);
        
        // Check if there's actual audio and calculate amplitude properly
        let totalAmplitude = 0;
        const samplePoints = 8; // Number of sample points to check
        
        // Sample specific points in the waveform rather than averaging everything
        for (let i = 0; i < samplePoints; i++) {
          // Get evenly spaced samples from the data array
          const index = Math.floor(i * (this.dataArray.length / samplePoints));
          const amp = Math.abs((this.dataArray[index] / 256) * 2 - 1);
          totalAmplitude += amp;
        }
        
        // Get the average of our sample points and apply amplification
        const audioAmp = (totalAmplitude / samplePoints) * 4.0; // Increased amplification for more dramatic effect
        
        // Take the larger of the minimum value or the actual audio
        rawAmplitude = Math.max(rawAmplitude, audioAmp);
      }
      
      // Much less smoothing for more immediate response to audio
      smoothedAmplitude = smoothedAmplitude * smoothingFactor + rawAmplitude * (1 - smoothingFactor);
      
      // Calculate sine wave oscillations for both circles
      const outerPhase = counter / 10; // Slightly faster oscillation
      const innerPhase = counter / 6; // Significantly faster oscillation for inner circle
      
      // Create oscillating values between 0 and 1 for both circles
      const outerOscillation = (Math.sin(outerPhase) + 1) * 0.5; // 0 to 1 range
      const innerOscillation = (Math.sin(innerPhase) + 1) * 0.5; // 0 to 1 range
      
      // Calculate target values with more pronounced audio effect
      const outerAmplitude = 0.05 + (smoothedAmplitude * 0.4); // Less min, more audio influence
      const innerAmplitude = 0.07 + (smoothedAmplitude * 0.7); // Less min, much more audio influence
      
      // Calculate target scales with more direct relation to audio
      targetValues[0] = 1.0 - (outerAmplitude * 0.1) + (outerAmplitude * outerOscillation);
      targetValues[1] = 1.0 - (innerAmplitude * 0.15) + (innerAmplitude * innerOscillation);
      
      // Almost no smoothing in movement for direct response
      circleValues[0] = circleValues[0] * 0.4 + targetValues[0] * 0.6; // Much more responsive
      circleValues[1] = circleValues[1] * 0.2 + targetValues[1] * 0.8; // Even more responsive for inner circle
      
      // Apply transformations with minimal smoothing
      this.outerCircle.style.transform = `scale(${circleValues[0]})`;
      this.innerCircle.style.transform = `scale(${circleValues[1]})`;
      
      // Add a more responsive shadow pulse effect
      const baseShadowIntensity = 3; // Lower base intensity
      const shadowPulse = (Math.sin(innerPhase) + 1) * 2; // Link to inner circle phase
      const audioBoost = smoothedAmplitude * 25; // Much higher audio influence
      
      const shadowIntensity = baseShadowIntensity + shadowPulse + audioBoost;
      this.innerCircle.style.boxShadow = `inset 0 0 ${shadowIntensity}px rgba(255, 255, 255, 0.7)`;
      
      // Slightly faster counter for quicker oscillation
      counter += 0.25;
      this.animationFrameId = requestAnimationFrame(animate);
    };
    
    // Start the animation
    animate();
  }
  
  /**
   * Stop speech animation
   */
  private stopSpeechAnimation(): void {
    if (this.animationFrameId) {
      cancelAnimationFrame(this.animationFrameId);
      this.animationFrameId = null;
    }
    
    // Clean up SVG elements
    if (this.visualizerContainer) {
      const svgElements = this.visualizerContainer.querySelectorAll('.voice-visualizer-svg');
      svgElements.forEach(svg => svg.remove());
    }
    
    // Reset the existing circles to default state
    if (this.outerCircle) {
      this.outerCircle.style.transform = 'scale(1)';
    }
    if (this.innerCircle) {
      this.innerCircle.style.transform = 'scale(1)';
      this.innerCircle.style.boxShadow = 'inset 0 0 15px rgba(255, 255, 255, 0.6)';
    }
  }
  
  /**
   * Show a notification message
   * @param message Message to display
   */
  showNotification(message: string): void {
    const notification = document.createElement('div');
    
    // Apply notification styles
    Object.assign(notification.style, {
      position: 'fixed',
      bottom: '90px',
      right: '20px',
      backgroundColor: 'rgba(0, 0, 0, 0.7)',
      color: 'white',
      padding: '10px 15px',
      borderRadius: '4px',
      fontSize: '14px',
      zIndex: '999998',
      transition: 'opacity 0.5s'
    });
    
    notification.textContent = message;
    document.body.appendChild(notification);
    
    // Remove after 3 seconds
    setTimeout(() => {
      notification.style.opacity = '0';
      setTimeout(() => {
        notification.remove();
      }, 500);
    }, 3000);
  }
  
  /**
   * Get the audio element for voice output
   */
  getAudioElement(): HTMLAudioElement {
    return this.audioElement;
  }
  
  /**
   * Add click handler to voice button
   * @param handler Function to call when voice button is clicked
   */
  onVoiceButtonClick(handler: () => void): void {
    this.clickHandler = handler;
  }
  
  /**
   * Update UI to reflect AI speaking state
   * @param isSpeaking Whether the AI is currently speaking
   */
  updateAISpeakingState(isSpeaking: boolean): void {
    if (!this.outerCircle || !this.innerCircle) return;
    
    if (isSpeaking) {
      // AI is speaking - start animation
      this.startSpeechAnimation();
    } else {
      // AI is not speaking - stop animation
      this.stopSpeechAnimation();
    }
  }
  
  /**
   * Position the button at specific coordinates
   * @param position Position object
   */
  positionButton(position: { bottom?: string; right?: string; top?: string; left?: string }): void {
    if (!this.container) return;
    
    // Reset positioning
    this.container.style.top = 'auto';
    this.container.style.right = 'auto';
    this.container.style.bottom = 'auto';
    this.container.style.left = 'auto';
    
    // Apply new positioning with adjustments to ensure it stays in viewport
    if (position.bottom) this.container.style.bottom = position.bottom;
    if (position.right) this.container.style.right = position.right;
    if (position.top) this.container.style.top = position.top;
    if (position.left) this.container.style.left = position.left;
    
    // Ensure the button stays within bounds
    this.ensureButtonInViewport();
  }
  
  /**
   * Ensure the button stays within the viewport
   */
  private ensureButtonInViewport(): void {
    // Get viewport dimensions
    const viewportWidth = window.innerWidth;
    const viewportHeight = window.innerHeight;
    
    // Add a small safety margin (in pixels)
    const margin = 10;
    
    // Apply constraints to ensure button stays within bounds
    const rect = this.container.getBoundingClientRect();
    
    // Check right edge
    if (rect.right > viewportWidth - margin) {
      this.container.style.right = margin + 'px';
    }
    
    // Check bottom edge
    if (rect.bottom > viewportHeight - margin) {
      this.container.style.bottom = margin + 'px';
    }
    
    // Check left edge (if positioned from left)
    if (rect.left < margin && this.container.style.left !== 'auto') {
      this.container.style.left = margin + 'px';
    }
    
    // Check top edge (if positioned from top)
    if (rect.top < margin && this.container.style.top !== 'auto') {
      this.container.style.top = margin + 'px';
    }
  }
  
  /**
   * Set audio volume
   * @param volume Volume level (0-1)
   */
  setVolume(volume: number): void {
    if (this.audioElement) {
      this.audioElement.volume = Math.max(0, Math.min(1, volume));
    }
  }
  
  /**
   * Clean up resources when the adapter is disposed
   */
  dispose(): void {
    // Stop animation
    this.stopSpeechAnimation();
    
    // Disconnect audio nodes and clean up
    if (this.audioSource) {
      this.audioSource.disconnect();
      this.audioSource = null;
    }
    
    if (this.audioAnalyser) {
      this.audioAnalyser.disconnect();
      this.audioAnalyser = null;
    }
    
    if (this.audioContext) {
      if (this.audioContext.state !== 'closed') {
        this.audioContext.close();
      }
      this.audioContext = null;
    }
    
    this.dataArray = null;
    
    // Remove the container if it exists
    if (this.container && this.container.parentNode) {
      this.container.parentNode.removeChild(this.container);
    }
  }

  /**
   * Set an external audio analyzer node for visualizations
   * @param analyser Audio analyzer node
   */
  setAudioAnalyser(analyser: AnalyserNode): void {
    // Store the external analyzer
    this.audioAnalyser = analyser;
    
    // Create a data array for the analyzer if it doesn't exist
    if (this.audioAnalyser && !this.dataArray) {
      this.dataArray = new Uint8Array(this.audioAnalyser.frequencyBinCount);
    }
  }
  
  /**
   * Get the current audio analyzer node
   */
  getAudioAnalyser(): AnalyserNode | null {
    return this.audioAnalyser;
  }
}

export default MinimalUIAdapter; 