import { ConversationMessage } from '../dal/StorageManager';

export interface ISummarizationResult {
  summary: string;
  error?: string;
}

export interface IOpenaiManager {
  summarizeConversation(messages: ConversationMessage[]): Promise<ISummarizationResult>;
}

class OpenaiManager implements IOpenaiManager {
  private apiKey: string | null = null;
  private apiEndpoint: string = 'https://api.openai.com/v1/chat/completions';
  
  /**
   * Create a new OpenAI manager for summarizing conversations
   * @param tokenProvider Function that provides the OpenAI API token
   */
  constructor(private tokenProvider: () => Promise<string | undefined>) {}
  
  /**
   * Summarize a conversation using OpenAI
   * @param messages Array of conversation messages to summarize
   * @returns Promise with summarization result
   */
  async summarizeConversation(messages: ConversationMessage[]): Promise<ISummarizationResult> {
    try {
      // Get API token
      const token = await this.tokenProvider();
      if (!token) {
        return {
          summary: '',
          error: 'Failed to get OpenAI API token'
        };
      }
      
      // Filter out system messages for summarization
      const conversationForSummary = messages.filter(msg => msg.role !== 'system');
      
      if (conversationForSummary.length === 0) {
        return {
          summary: 'No conversation to summarize',
        };
      }
      
      // Prepare conversation messages for the API
      const apiMessages = [
        {
          role: 'system',
          content: `Summarize the following conversation in a concise paragraph. 
          Focus on the main topics discussed, key questions, and important information shared.
          Keep the summary under 200 words and make it informative.`
        },
        ...conversationForSummary.map(msg => ({
          role: msg.role,
          content: msg.content
        }))
      ];
      
      // Make API request to OpenAI
      const response = await fetch(this.apiEndpoint, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({
          model: 'gpt-4o-mini',
          messages: apiMessages,
          max_tokens: 300
        })
      });
      
      if (!response.ok) {
        const errorData = await response.json();
        return {
          summary: '',
          error: `OpenAI API error: ${errorData.error?.message || response.statusText}`
        };
      }
      
      const data = await response.json();
      const summary = data.choices[0]?.message?.content?.trim() || '';
      
      return { summary };
    } catch (error) {
      console.error('Error summarizing conversation:', error);
      return {
        summary: '',
        error: `Error: ${error instanceof Error ? error.message : String(error)}`
      };
    }
  }
}

export default OpenaiManager; 