/**
 * Audio Recorder
 * Records audio from WebRTC streams for storage and analysis
 */

import { AudioRecording } from '../../types';
import logger from '../../utils/logger';

export interface IAudioRecorder {
  startRecording(userId: string, stream: MediaStream): void;
  stopRecording(): Promise<AudioRecording | null>;
  isRecording(): boolean;
}

class AudioRecorder implements IAudioRecorder {
  private mediaRecorder: MediaRecorder | null = null;
  private recordedChunks: Blob[] = [];
  private recording: boolean = false;
  private startTime: number = 0;
  private userId: string = '';
  private audioFormat: string = 'audio/webm';

  constructor(audioFormat: string = 'audio/webm') {
    this.audioFormat = audioFormat;
  }

  /**
   * Start recording audio from a MediaStream
   * @param userId User ID to associate with the recording
   * @param stream The MediaStream to record from
   */
  startRecording(userId: string, stream: MediaStream): void {
    if (this.recording) {
      logger.warn('Recording already in progress.');
      return;
    }

    try {
      this.userId = userId;
      this.startTime = Date.now();
      this.recordedChunks = [];
      this.recording = true;

      // Create a new MediaRecorder with the provided stream
      this.mediaRecorder = new MediaRecorder(stream, {
        mimeType: this.audioFormat
      });

      // Add data handler
      this.mediaRecorder.ondataavailable = (event) => {
        if (event.data.size > 0) {
          this.recordedChunks.push(event.data);
        }
      };

      // Handle recording errors
      this.mediaRecorder.onerror = (error) => {
        logger.error('MediaRecorder error:', error);
        this.recording = false;
      };

      // Start recording
      this.mediaRecorder.start(1000); // Collect chunks every second
      logger.log(`Started recording for user ${userId}`);
    } catch (error) {
      logger.error('Error starting audio recording:', error);
      this.recording = false;
    }
  }

  /**
   * Stop the current recording and return the recording data
   * @returns Promise with the recording data or null if no recording was in progress
   */
  async stopRecording(): Promise<AudioRecording | null> {
    if (!this.recording || !this.mediaRecorder) {
      logger.warn('No recording in progress to stop.');
      return null;
    }

    return new Promise((resolve) => {
      if (!this.mediaRecorder) {
        resolve(null);
        return;
      }

      // Create a handler for when the recording stops
      this.mediaRecorder.onstop = () => {
        // Create and return the recording data
        const recording: AudioRecording = {
          userId: this.userId,
          startTime: this.startTime,
          audioBlobs: [...this.recordedChunks]
        };

        // Reset recording state
        this.recording = false;
        this.mediaRecorder = null;
        this.recordedChunks = [];

        logger.log(`Stopped recording for user ${this.userId}, collected ${recording.audioBlobs.length} chunks`);
        resolve(recording);
      };

      // Stop the recorder which will trigger the onstop event
      this.mediaRecorder.stop();
    });
  }

  /**
   * Check if recording is currently in progress
   * @returns True if recording, false otherwise
   */
  isRecording(): boolean {
    return this.recording;
  }

}

export default AudioRecorder; 