/**
 * Core Module Index
 * Exports all core functionality
 */

// Business Logic Layer
import { 
  VoiceAssistantManager, 
  WebRTCManager, 
  ContentExtractor,
  AudioRecorder,
  OpenaiManager,
  IVoiceAssistantManager,
  IWebRTCManager,
  IContentExtractor,
  IAudioRecorder,
  IOpenaiManager
} from './bl';

// Data Access Layer
import {
  StorageManager,
  IStorageManager,
  FirebaseService,
  IFirebaseService
} from './dal';

// Factory function to create a VoiceAssistantManager with all dependencies
import { VoiceAssistantConfig } from '../types';
import { IPlatformAdapter } from '../platform/IPlatformAdapter';
import { IUIAdapter } from '../ui/IUIAdapter';
import { defaultConfig } from '../config/defaults';
import logger from '../utils/logger';

/**
 * Creates a fully configured voice assistant with all dependencies
 * @param platform Platform adapter
 * @param ui UI adapter 
 * @param config Optional configuration
 * @returns Configured VoiceAssistantManager
 */
function createVoiceAssistant(
  platform: IPlatformAdapter,
  ui: IUIAdapter,
  config?: Partial<VoiceAssistantConfig>
): IVoiceAssistantManager {
  // Merge configuration
  const mergedConfig = { ...defaultConfig, ...config };
  
  // Set debug mode if specified in settings
  const debugMode = mergedConfig.settings.debugMode ?? false;
  logger.setDebugMode(debugMode);
  
  // Create Firebase Service if configured
  let firebaseService: IFirebaseService | undefined;
  if (mergedConfig.firebaseConfig) {
    firebaseService = new FirebaseService(mergedConfig.firebaseConfig);
  }
  
  // Create DAL components
  const storageManager = new StorageManager(
    platform, 
    mergedConfig.settings.maxConversationHistory,
    firebaseService,
    mergedConfig.settings.recordConversations,
    mergedConfig.userIdentifier
  );
  
  // Create audio recorder
  const audioRecorder = new AudioRecorder(mergedConfig.settings.audioFormat);
  
  // Create content extractor
  const contentExtractor = new ContentExtractor();
  
  // Create token provider function
  const tokenProvider = async () => {
    try {      
      // Use API key from config if available
      if (mergedConfig.openAIConfig.apiKey) {
        return mergedConfig.openAIConfig.apiKey;
      }

      return undefined;
    } catch (error) {
      logger.error('Failed to get token:', error);
      throw new Error('Unable to obtain OpenAI API token. Please provide an API key in the configuration or ensure the platform can provide a token.');
    }
  };
  
  // Create WebRTC manager
  const webrtcManager = new WebRTCManager(
    tokenProvider, 
    mergedConfig.openAIConfig,
    audioRecorder
  );
  
  // Create OpenAI manager for summarization
  const openaiManager = new OpenaiManager(tokenProvider);
  
  // Create and return voice assistant manager
  return new VoiceAssistantManager(
    platform,
    ui,
    webrtcManager,
    contentExtractor,
    storageManager,
    mergedConfig,
    audioRecorder,
    openaiManager,
    firebaseService
  );
}

// Export factory function
export { createVoiceAssistant };

// Export all business logic components
export { 
  VoiceAssistantManager, 
  WebRTCManager, 
  ContentExtractor,
  AudioRecorder,
  OpenaiManager,
  StorageManager,
  FirebaseService
};

// Export all interfaces
export type { 
  IVoiceAssistantManager,
  IWebRTCManager,
  IContentExtractor,
  IAudioRecorder,
  IOpenaiManager,
  IStorageManager,
  IFirebaseService
};

// Export createVoiceAssistant as default for simple import
export default createVoiceAssistant; 