/**
 * Logger Utility
 * Provides conditional logging based on debug mode setting
 */

/**
 * Logger class to handle conditional logging
 */
class Logger {
  private isDebugMode: boolean = false;
  private prefix: string = '[Voice Assistant SDK]';

  /**
   * Set the debug mode state
   * @param isDebug Whether debug mode is enabled
   */
  setDebugMode(isDebug: boolean): void {
    this.isDebugMode = isDebug;
  }

  /**
   * Check if debug mode is currently enabled
   * @returns Whether debug mode is enabled
   */
  isDebugModeEnabled(): boolean {
    return this.isDebugMode;
  }

  /**
   * Set the log prefix
   * @param prefix Prefix to add to log messages
   */
  setPrefix(prefix: string): void {
    this.prefix = prefix;
  }

  /**
   * Log information message (only in debug mode)
   * @param message Message to log
   * @param optionalParams Additional parameters to log
   */
  log(message: string, ...optionalParams: any[]): void {
    if (this.isDebugMode) {
      console.log(`${this.prefix} ${message}`, ...optionalParams);
    }
  }

  /**
   * Log warning message (only in debug mode)
   * @param message Warning message
   * @param optionalParams Additional parameters to log
   */
  warn(message: string, ...optionalParams: any[]): void {
    if (this.isDebugMode) {
      console.warn(`${this.prefix} ${message}`, ...optionalParams);
    }
  }

  /**
   * Log error message (always logged)
   * @param message Error message
   * @param optionalParams Additional parameters to log
   */
  error(message: string, ...optionalParams: any[]): void {
    // Errors are always logged for safety
    console.error(`${this.prefix} ${message}`, ...optionalParams);
  }

  /**
   * Log error message (only in debug mode)
   * @param message Error message 
   * @param optionalParams Additional parameters to log
   */
  debugError(message: string, ...optionalParams: any[]): void {
    if (this.isDebugMode) {
      console.error(`${this.prefix} ${message}`, ...optionalParams);
    }
  }
}

// Create a singleton instance
const logger = new Logger();

export default logger; 