/**
 * WebRTC Connection Module
 * Handles real-time communication with OpenAI's APIs
 */

import { OpenAIConfig, OpenAIResponseCreate, OpenAISessionUpdate } from '../../types';
import { IAudioRecorder } from './AudioRecorder';
import logger from '../../utils/logger';

// Event handler types
type EventMap = {
  onConnecting: () => void;
  onConnected: (remoteStream: MediaStream) => void;
  onDisconnected: () => void;
  onError: (error: Error) => void;
  onMessage: (message: any) => void;
};

export interface IWebRTCManager {
  connect(): Promise<boolean>;
  disconnect(): void;
  updateInstructions(instructions: string): boolean;
  triggerAssistantResponse(message: string): boolean;
  sendMessage(message: any): boolean;
  getStatus(): { isConnected: boolean; isConnecting: boolean };
  getReconnectAttempts(): number;
  resetReconnectAttempts(): void;
  on<K extends keyof EventMap>(event: K, callback: EventMap[K]): IWebRTCManager;
  getLocalStream(): MediaStream | null;
  getRemoteStream(): MediaStream | null;
  stopCurrentResponse(): void;
  getAudioAnalyser(): AnalyserNode | null;
}

// Define a simple connection state enum
enum ConnectionState {
  DISCONNECTED,
  CONNECTING,
  CONNECTED
}

class WebRTCManager implements IWebRTCManager {
  private config: OpenAIConfig;
  private tokenProvider: () => Promise<string | undefined>;
  
  // Connection state
  private peerConnection: RTCPeerConnection | null = null;
  private dataChannel: RTCDataChannel | null = null;
  private localStream: MediaStream | null = null;
  private remoteStream: MediaStream | null = null;
  private connectionState: ConnectionState = ConnectionState.DISCONNECTED;
  private reconnectAttempts: number = 0;
  private connectionTimeout: number | null = null;
  
  // Audio recorder
  private audioRecorder: IAudioRecorder | null = null;
  
  // Event callbacks
  private events: EventMap = {
    onConnecting: () => {},
    onConnected: () => {},
    onDisconnected: () => {},
    onError: () => {},
    onMessage: () => {}
  };
  
  // Constants
  private readonly CONNECTION_TIMEOUT_MS: number = 15000; // 15 seconds
  
  private audioContext: AudioContext | null = null;
  private audioAnalyser: AnalyserNode | null = null;
  
  /**
   * Create a new WebRTC manager
   * @param tokenProvider Function that returns a token for OpenAI API
   * @param config OpenAI configuration
   * @param audioRecorder Optional audio recorder for recording conversations
   */
  constructor(
    tokenProvider: () => Promise<string | undefined>, 
    config: OpenAIConfig,
    audioRecorder?: IAudioRecorder
  ) {
    this.tokenProvider = tokenProvider;
    this.config = config;
    this.audioRecorder = audioRecorder || null;
  }
  
  /**
   * Set an event handler
   * @param event Event name
   * @param callback Function to call when event occurs
   */
  on<K extends keyof EventMap>(event: K, callback: EventMap[K]): this {
    this.events[event] = callback;
    return this;
  }
  
  /**
   * Start WebRTC connection
   */
  async connect(): Promise<boolean> {
    if (this.connectionState !== ConnectionState.DISCONNECTED) return false;
    
    this.connectionState = ConnectionState.CONNECTING;
    this.events.onConnecting();
    
    // Set connection timeout
    this.connectionTimeout = window.setTimeout(() => {
      if (this.connectionState === ConnectionState.CONNECTING) {
        this._handleError(new Error('Connection timeout: Failed to establish WebRTC connection within the time limit.'));
      }
    }, this.CONNECTION_TIMEOUT_MS);
    
    try {
      // Get token from provider (adapter)
      const token = await this.tokenProvider();
      
      if (!token) {
        throw new Error('No token provided. Please ensure the token provider returns a valid token.');
      }
      
      // Access microphone
      try {
        this.localStream = await navigator.mediaDevices.getUserMedia({ audio: true });
      } catch (err) {
        // Handle permission denial specifically
        if (err instanceof DOMException && (err.name === 'NotAllowedError' || err.name === 'PermissionDeniedError')) {
          throw new Error('Microphone access was denied. Please allow microphone access to use this feature.');
        }
        throw err; // Re-throw other errors
      }
      
      // Set up WebRTC
      this.peerConnection = new RTCPeerConnection();
      
      // Monitor ICE connection state
      this.peerConnection.oniceconnectionstatechange = () => {
        if (!this.peerConnection) return;
        
        logger.log('ICE connection state:', this.peerConnection.iceConnectionState);
        
        switch (this.peerConnection.iceConnectionState) {
          case 'failed':
            this._handleError(new Error('ICE connection failed. This could be due to network issues or firewall restrictions.'));
            break;
            
          case 'disconnected':
            logger.warn('ICE connection disconnected. Attempting to recover...');
            break;
            
          case 'closed':
            this.disconnect();
            break;
        }
      };
      
      // Add local audio tracks
      this.localStream.getAudioTracks().forEach(track => {
        if (this.peerConnection) {
          this.peerConnection.addTrack(track, this.localStream!);
        }
      });
      
      // Set up remote stream
      this.remoteStream = new MediaStream();
      
      if (this.peerConnection) {
        this.peerConnection.ontrack = (event) => {
          event.streams[0].getTracks().forEach(track => {
            if (this.remoteStream) {
              this.remoteStream.addTrack(track);
            }
          });

          this.remoteStream = event.streams[0];
          
          // Create audio analyzer for visualization
          this.createAudioAnalyser();
        };
        
        // Create data channel
        this.dataChannel = this.peerConnection.createDataChannel('oai-events');
        this.dataChannel.onmessage = (event) => this._handleDataMessage(event);
        this.dataChannel.onclose = () => this._handleDataChannelClose();
        
        // Add onopen handler to process any pending messages
        this.dataChannel.onopen = () => {
          logger.log('Data channel is now open');
          
          // Clear the connection timeout
          if (this.connectionTimeout) {
            clearTimeout(this.connectionTimeout);
            this.connectionTimeout = null;
          }
          
          // Now we can actually consider ourselves connected
          this.connectionState = ConnectionState.CONNECTED;
          this.reconnectAttempts = 0;
          
          // Initialize the session
          this._initializeSession();
          
          // Trigger the connected event with the remote stream
          if (this.remoteStream) {
            this.events.onConnected(this.remoteStream);
          }
        };
        
        // Create and send offer
        const offer = await this.peerConnection.createOffer();
        await this.peerConnection.setLocalDescription(offer);
        
        // Send offer to OpenAI
        const baseUrl = "https://api.openai.com/v1/realtime";
        const sdpResponse = await fetch(`${baseUrl}?model=${this.config.realtimeModel}`, {
          method: "POST",
          body: offer.sdp,
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/sdp"
          },
        });
        
        if (!sdpResponse.ok) {
          throw new Error(`SDP response error: ${sdpResponse.status}`);
        }
        
        // Set remote description
        const answer = {
          type: "answer",
          sdp: await sdpResponse.text(),
        };
        await this.peerConnection.setRemoteDescription(answer as RTCSessionDescriptionInit);
        
        // WebRTC signaling is complete, but we're not fully connected
        // until the data channel opens. We remain in connecting state.
        
        return true;
      }
      
      return false;
    } catch (error) {
      this._handleError(error instanceof Error ? error : new Error(String(error)));
      return false;
    }
  }
  
  /**
   * Initialize session with OpenAI
   */
  private _initializeSession(): void {
    const sessionUpdate: OpenAISessionUpdate = {
      type: "session.update",
      session: {
        modalities: ["text", "audio"],
        input_audio_format: "pcm16",
        output_audio_format: "pcm16",
        voice: this.config.voice,
        input_audio_transcription: {
          model: this.config.whisperModel,
        },
        // Enable server VAD (Voice Activity Detection) for automatic turn detection
        turn_detection: {
          type: "server_vad",
          threshold: 0.7, // 0.5
          prefix_padding_ms: 300,
          silence_duration_ms: 700, // 500
          create_response: true // Automatically create a response when speech ends
        }
      },
    };
    
    this.sendMessage(sessionUpdate);
  }
  
  /**
   * Handle incoming data channel messages
   * @param event Message event
   */
  private _handleDataMessage(event: MessageEvent): void {
    try {
      const message = JSON.parse(event.data);
      
      // Handle only critical errors at this level - other processing will be done by VoiceAssistantManager
      if (message.type === 'error') {
        logger.error('OpenAI Realtime API error:', message.error);
        this.events.onError(new Error(message.error.message || 'Unknown API error'));
      }
      this.events.onMessage(message);
    } catch (error) {
      logger.error('Error parsing WebRTC message:', error);
    }
  }
  
  /**
   * Handle data channel close
   */
  private _handleDataChannelClose(): void {
    this.connectionState = ConnectionState.DISCONNECTED;
    this.events.onDisconnected();
    
    // Increment reconnection attempts
    this.reconnectAttempts++;
    
    // Implement automatic reconnection logic if needed
    // For example:
    // if (this.reconnectAttempts < MAX_RECONNECT_ATTEMPTS) {
    //   setTimeout(() => this.connect(), this.reconnectAttempts * 1000);
    // }
  }
  
  /**
   * Handle connection errors
   * @param error Error that occurred
   */
  private _handleError(error: Error): void {
    logger.error('WebRTC connection error:', error);
    
    // Clear any pending connection timeout
    if (this.connectionTimeout) {
      clearTimeout(this.connectionTimeout);
      this.connectionTimeout = null;
    }
    
    this.connectionState = ConnectionState.DISCONNECTED;
    this.events.onError(error);
    
    // Clean up resources
    this.disconnect();
  }
  
  /**
   * Disconnect and clean up
   */
  disconnect(): void {
    // Clear any pending connection timeout
    if (this.connectionTimeout) {
      clearTimeout(this.connectionTimeout);
      this.connectionTimeout = null;
    }
    
    // Close data channel
    if (this.dataChannel) {
      try {
        this.dataChannel.close();
      } catch (e) {
        logger.error('Error closing data channel:', e);
      }
      this.dataChannel = null;
    }
    
    // Close peer connection
    if (this.peerConnection) {
      try {
        this.peerConnection.close();
      } catch (e) {
        logger.error('Error closing peer connection:', e);
      }
      this.peerConnection = null;
    }
    
    // Stop local stream
    if (this.localStream) {
      try {
        this.localStream.getTracks().forEach(track => {
          track.stop();
          track.enabled = false;
        });
      } catch (e) {
        logger.error('Error stopping local tracks:', e);
      }
      this.localStream = null;
    }
    
    // Clean up remote stream
    if (this.remoteStream) {
      try {
        this.remoteStream.getTracks().forEach(track => {
          track.stop();
          track.enabled = false;
        });
      } catch (e) {
        logger.error('Error stopping remote tracks:', e);
      }
      this.remoteStream = null;
    }
    
    this.connectionState = ConnectionState.DISCONNECTED;
    this.events.onDisconnected();
  }
  
  /**
   * Check if the data channel is ready to send messages
   * @returns True if the data channel is open and ready
   */
  private isDataChannelReady(): boolean {
    return this.dataChannel !== null && this.dataChannel.readyState === 'open';
  }
  
  /**
   * Get connection status
   */
  getStatus(): { isConnected: boolean; isConnecting: boolean } {
    // For connected state, make sure data channel is actually ready
    const realConnected = this.connectionState === ConnectionState.CONNECTED && this.isDataChannelReady();
    
    return {
      isConnected: realConnected,
      isConnecting: this.connectionState === ConnectionState.CONNECTING || 
                   (this.connectionState === ConnectionState.CONNECTED && !this.isDataChannelReady())
    };
  }
  
  /**
   * Get the number of reconnection attempts
   */
  getReconnectAttempts(): number {
    return this.reconnectAttempts;
  }
  
  /**
   * Reset reconnection attempts counter
   */
  resetReconnectAttempts(): void {
    this.reconnectAttempts = 0;
  }
  
  /**
   * Send message to data channel
   * @param message Message to send
   */
  sendMessage(message: any): boolean {
    if (this.connectionState === ConnectionState.CONNECTED && this.isDataChannelReady()) {
      this.dataChannel!.send(JSON.stringify(message));
      return true;
    }
    return false;
  }
  
  /**
   * Update system instructions
   * @param instructions New system instructions
   */
  updateInstructions(instructions: string): boolean {
    logger.log('Updating instructions:', instructions);
    
    const sessionUpdate: OpenAISessionUpdate = {
      type: "session.update",
      session: {
        instructions: instructions
      },
    };
    
    return this.sendMessage(sessionUpdate);
  }
  
  /**
   * Trigger assistant to speak a specific message
   * @param message Message for the assistant to speak
   */
  triggerAssistantResponse(message: string): boolean {
    // We can use our helper method here
    if (this.connectionState !== ConnectionState.CONNECTED || !this.isDataChannelReady()) {
      return false;
    }
    
    const responseCreate: OpenAIResponseCreate = {
      type: "response.create",
      response: {
        modalities: ["text", "audio"],
        instructions: message,
        voice: this.config.voice,
        output_audio_format: "pcm16",
        temperature: 1
      }
    };
    
    return this.sendMessage(responseCreate);
  }
  
  /**
   * Get the local MediaStream
   * @returns The local MediaStream or null if not available
   */
  getLocalStream(): MediaStream | null {
    return this.localStream;
  }

  /**
   * Get the remote MediaStream
   * @returns The remote MediaStream or null if not available
   */
  getRemoteStream(): MediaStream | null {
    return this.remoteStream;
  }

  /**
   * Stop the current response if one is in progress
   */
  stopCurrentResponse(): void {
    this.sendMessage({
      type: "response.cancel",
    });

  }

  /**
   * Create audio analyzer for the remote stream
   */
  private createAudioAnalyser(): void {
    if (!this.remoteStream) return;
    
    try {
      // Create audio context if it doesn't exist
      if (!this.audioContext) {
        this.audioContext = new (window.AudioContext || (window as any).webkitAudioContext)();
      }
      
      // Create analyzer node
      this.audioAnalyser = this.audioContext.createAnalyser();
      this.audioAnalyser.fftSize = 256; // Small size for performance
      
      // Connect remote stream to analyzer
      const source = this.audioContext.createMediaStreamSource(this.remoteStream);
      source.connect(this.audioAnalyser);
      
      logger.log('Created audio analyzer for remote stream');
    } catch (error) {
      logger.error('Error creating audio analyzer:', error);
    }
  }
  
  /**
   * Get the audio analyzer node for visualizations
   */
  getAudioAnalyser(): AnalyserNode | null {
    return this.audioAnalyser;
  }
}

export default WebRTCManager; 