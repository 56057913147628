/**
 * Voice Assistant SDK
 * Entry point for the JavaScript SDK
 */

import SDKAdapter from '../platform/sdkAdapter';
import MinimalUIAdapter from '../ui/minimalUIAdapter';
import { createVoiceAssistant as createVoiceAssistantCore } from '../core';
import { defaultConfig } from '../config/defaults';
import { VoiceAssistantConfig } from '../types';
import logger from '../utils/logger';

// SDK Configuration interface
interface SDKOptions {
  // OpenAI configuration
  openAIConfig?: {
    apiKey: string;
    voice?: string;
  };
  
  // UI configuration
  containerId?: string;
  buttonPosition?: {
    bottom?: string;
    right?: string;
    top?: string;
    left?: string;
  };
  
  // Allowed domains (optional)
  allowedDomains?: string[];
  
  // User identification (optional)
  userIdentifier?: string;
  
  // Settings
  settings?: {
    voiceVolume?: number;
  };

  // Error handling
  onError?: (error: Error) => void;
  
  // Debug mode for additional logging
  debug?: boolean;
}

/**
 * Safe execution wrapper to isolate SDK operations
 * This prevents SDK errors from affecting the client application
 */
function safeExecute<T>(fn: () => T, fallback: T, errorHandler?: (error: Error) => void): T {
  try {
    return fn();
  } catch (error) {
    // Log the error but don't let it propagate
    logger.error('Error:', error);
    
    // Call custom error handler if provided
    if (errorHandler && error instanceof Error) {
      try {
        errorHandler(error);
      } catch (handlerError) {
        // Even the error handler could throw, so we catch that too
        logger.error('Error in error handler:', handlerError);
      }
    }
    
    // Return fallback value to continue operation
    return fallback;
  }
}

/**
 * Safe async execution wrapper
 */
async function safeExecuteAsync<T>(
  fn: () => Promise<T>, 
  fallback: T, 
  errorHandler?: (error: Error) => void
): Promise<T> {
  try {
    return await fn();
  } catch (error) {
    logger.error('Async Error:', error);
    
    if (errorHandler && error instanceof Error) {
      try {
        errorHandler(error);
      } catch (handlerError) {
        logger.error('Error in async error handler:', handlerError);
      }
    }
    
    return fallback;
  }
}

/**
 * Create a voice assistant instance
 * @param options SDK options
 * @returns Voice assistant instance
 */
export function createVoiceAssistant(options: SDKOptions = {}) {
  // Set up global error handling for the SDK
  const errorHandler = options.onError;
  const debugMode = !!options.debug;
  
  // Set debug mode in logger
  console.log('debugMode', debugMode);
  logger.setDebugMode(debugMode);
  
  // Log startup in debug mode
  logger.log('Initializing with options:', options);
  
  // Create everything inside a try-catch to handle initialization errors
  try {
    // Create platform adapter (icons are auto-detected)
    const platform = new SDKAdapter();
    
    // Create UI adapter with optional container
    const ui = new MinimalUIAdapter(options.containerId);
    
    // Merge default config with SDK options
    const config: Partial<VoiceAssistantConfig> = {
      ...defaultConfig,
      openAIConfig: {
        ...defaultConfig.openAIConfig,
        // Only set API key if provided
        ...(options.openAIConfig?.apiKey ? { apiKey: options.openAIConfig.apiKey } : {}),
        // Merge other properties if openAIConfig exists
        ...(options.openAIConfig ? { voice: options.openAIConfig.voice || defaultConfig.openAIConfig.voice } : {})
      },
      settings: {
        ...defaultConfig.settings,
        ...(options.settings || {}),
        // Set debug mode based on options
        debugMode: debugMode
      },
      // Pass userIdentifier if provided
      ...(options.userIdentifier ? { userIdentifier: options.userIdentifier } : {})
    };
    
    // Create voice assistant using factory
    const assistant = createVoiceAssistantCore(platform, ui, config);
    
    // Wrap all public methods with error protection
    return {
      /**
       * Initialize the voice assistant
       */
      init: async () => {
        return await safeExecuteAsync(async () => {
          await assistant.init();
          
          // Apply custom button position if specified
          if (options.buttonPosition && 'positionButton' in ui) {
            safeExecute(() => {
              (ui as any).positionButton(options.buttonPosition);
            }, undefined, errorHandler);
          }
          
          logger.log('Successfully initialized');
          
          return assistant;
        }, assistant, errorHandler);
      },
      
      /**
       * Start the voice assistant
       */
      start: () => safeExecute(() => assistant.startVoiceAssistant(), undefined as any, errorHandler),
      
      /**
       * Stop the voice assistant
       */
      stop: () => safeExecute(() => assistant.stopVoiceAssistant(), undefined as any, errorHandler),
      
      /**
       * Toggle the voice assistant on/off
       */
      toggle: () => safeExecute(() => assistant.toggle(), undefined as any, errorHandler),
      
      /**
       * Clean up resources
       */
      dispose: () => safeExecute(() => {
        logger.log('Disposing resources');
        assistant.dispose();
        return true;
      }, false, errorHandler)
    };
  } catch (initError) {
    // Handle initialization failures gracefully
    logger.error('Failed to initialize:', initError);
    
    if (errorHandler && initError instanceof Error) {
      try {
        errorHandler(initError);
      } catch (handlerError) {
        logger.error('Error in init error handler:', handlerError);
      }
    }
    
    // Return non-functional but safe dummy implementation that won't crash
    return {
      init: async () => ({ success: false, error: initError }),
      start: () => false,
      stop: () => false,
      toggle: () => false,
      dispose: () => false
    };
  }
}

// Export the create function as default
export default createVoiceAssistant; 