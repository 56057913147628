/**
 * Firebase Storage Service
 * Handles storage for conversation records and logs using Firebase
 */

import { FirebaseConfig, AudioRecording, VoiceAssistantState } from '../../types';
import { ConversationMessage } from './StorageManager';
import logger from '../../utils/logger';

// Import Firebase modules
import { initializeApp, getApps } from 'firebase/app';
import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { getFirestore, doc, setDoc, addDoc, collection, getDoc, getDocs, query, where, orderBy, limit, updateDoc, arrayUnion, deleteDoc, deleteField } from 'firebase/firestore';

export interface IFirebaseService {
  uploadAudioRecording(recording: AudioRecording, transcript?: string): Promise<string>;
  logConversationMessage(userId: string, message: ConversationMessage): Promise<void>;
  logUserSession(userId: string): Promise<void>;
  
  // New methods for storage operations
  saveAssistantState(userId: string, state: Partial<VoiceAssistantState>): Promise<void>;
  getAssistantState(userId: string): Promise<Partial<VoiceAssistantState>>;
  saveConversationHistory(userId: string, history: ConversationMessage[]): Promise<void>;
  getConversationHistory(userId: string): Promise<ConversationMessage[]>;
  addMessageToHistory(userId: string, message: ConversationMessage): Promise<void>;
  updateSystemMessage(userId: string, content: string): Promise<void>;
  clearConversationHistory(userId: string): Promise<void>;
  
  // New methods for past conversations
  storePastConversation(userId: string, conversation: ConversationMessage[], sessionTime: number): Promise<void>;
  getPastConversations(userId: string): Promise<Array<{timestamp: number, messages: ConversationMessage[]}>>;
  
  // New methods for conversation summaries
  storePastConversationSummary(userId: string, summary: string, sessionTime: number): Promise<void>;
  getPastConversationSummaries(userId: string): Promise<Array<{timestamp: number, summary: string}>>;
}

// Interface for transcript message format
interface TranscriptMessage {
  role: 'user' | 'assistant';
  content: string;
  timestamp?: number;
}

class FirebaseService implements IFirebaseService {
  private config: FirebaseConfig;
  private firebaseApp: any;
  private storage: any;
  private firestore: any;
  private isInitialized: boolean = false;
  // Store conversation logs in memory instead of creating separate files
  private conversationLogs: Map<string, any[]> = new Map();

  constructor(config: FirebaseConfig) {
    this.config = config;
    
    // Initialize Firebase
    if (this.config.firebaseConfig) {
      try {
        this.firebaseApp = initializeApp(this.config.firebaseConfig);
        this.storage = getStorage(this.firebaseApp);
        this.firestore = getFirestore(this.firebaseApp);
        this.isInitialized = true;
      } catch (error) {
        logger.error('Error initializing Firebase:', error);
        this.isInitialized = false;
      }
    } else {
      logger.warn('Firebase configuration missing - storage features disabled');
    }
  }

  /**
   * Upload an audio recording to Firebase Storage
   * @param recording The audio recording to upload
   * @param transcript Optional transcript of the recording
   * @returns The URL of the uploaded audio
   */
  async uploadAudioRecording(recording: AudioRecording, transcript?: string): Promise<string> {
    if (!this.isInitialized) {
      logger.warn('Firebase not initialized - unable to upload recording');
      return `error://firebase-not-initialized`;
    }
    
    try {
      // Format the filename: user_guid_timestamp
      const timestamp = new Date(recording.startTime).toISOString().replace(/[:.]/g, '-');
      const filename = `${recording.userId}_${timestamp}`;
      
      // Create a single blob from all chunks
      const audioBlob = new Blob(recording.audioBlobs, { type: 'audio/webm' });
      
      // Check file size - warn if large
      if (audioBlob.size > 10 * 1024 * 1024) { // 10MB
        logger.warn(`Large file (${(audioBlob.size / (1024 * 1024)).toFixed(2)}MB). Consider compression.`);
      }
      
      logger.log(`Uploading recording (${(audioBlob.size / 1024).toFixed(2)}KB) to Firebase Storage`);
      
      
      // Create metadata for the audio file
      const metadata = {
        contentType: 'audio/webm',
        customMetadata: {
          userId: recording.userId,
          timestamp: timestamp,
          duration: recording.audioBlobs.length > 0 ? 'unknown' : '0',
          uploadedAt: new Date().toISOString()
        }
      };
      
      // Upload both the audio and the JSON data
      const audioFilePath = `recordings/${filename}.webm`;      
      const audioFileRef = ref(this.storage, audioFilePath);
      
      // Upload in parallel
      const [audioUploadResult] = await Promise.all([
        uploadBytes(audioFileRef, audioBlob, metadata),
      ]);
      
      // Get the download URL for the audio file
      const downloadUrl = await getDownloadURL(audioFileRef);
      return downloadUrl;
    } catch (error) {
      logger.error('Error uploading audio recording:', error);
      return `error://upload-failed`;
    }
  }

  /**
   * Log a conversation message to memory (not Firebase)
   * @param userId The user's unique ID
   * @param message The message to log
   */
  async logConversationMessage(userId: string, message: ConversationMessage): Promise<void> {
    // Check if we should log based on the current debug mode setting
    if (!logger.isDebugModeEnabled()) {
      return;
    }
    
    try {
      // Create log entry with browser info
      const logEntry = {
        userId,
        role: message.role,
        content: message.content,
        timestamp: message.timestamp || Date.now(),
        dateTime: new Date(message.timestamp || Date.now()).toISOString(),
        browserInfo: this.getBrowserInfo(),
        userAgent: navigator.userAgent
      };
      
      // Store in memory map instead of creating a file
      if (!this.conversationLogs.has(userId)) {
        this.conversationLogs.set(userId, []);
      }
      
      // Add to the user's log collection
      this.conversationLogs.get(userId)?.push(logEntry);
      
      logger.log(`Message logged to memory store for user ${userId}`);
    } catch (error) {
      logger.error('Error logging message:', error);
    }
  }
  
  /**
   * Get basic browser information for logging
   * @returns Object with browser info
   */
  private getBrowserInfo(): object {
    return {
      width: window.innerWidth,
      height: window.innerHeight,
      language: navigator.language,
      platform: navigator.platform,
      vendor: navigator.vendor
    };
  }

  /**
   * Log a user session in Firestore
   * @param userId The ID of the user
   * @returns A promise that resolves when the session is logged
   */
  async logUserSession(userId: string): Promise<void> {
    logger.log('logUserSession', userId);
    try {
      // Check if Firebase config exists
      if (!this.config.firebaseConfig) {
        logger.log('Firebase config not provided');
        return;
      }

      // Initialize Firebase if needed
      if (!this.isInitialized) {
        // Dynamic import of Firebase
        const firebase = await import('firebase/app');
        await import('firebase/firestore');
        
        if (!this.firebaseApp) {
          if (firebase.getApps().length === 0) {
            this.firebaseApp = firebase.initializeApp(this.config.firebaseConfig);
          } else {
            this.firebaseApp = firebase.getApp();
          }
          
          this.firestore = getFirestore(this.firebaseApp);
          this.isInitialized = true;
        }
      }
      
      // Only proceed with logging if debug mode is enabled
      if (!logger.isDebugModeEnabled() || !this.firestore) {
        logger.log('Firebase logging disabled or not initialized');
        return;
      }
      
      // Set with merge to avoid overwriting existing data
      await setDoc(doc(this.firestore, 'users', userId), {
        lastSessionTime: new Date().toISOString(),
      }, { merge: true });
      
      logger.log('User session logged successfully');
    } catch (error) {
      logger.error('Error logging user session:', error);
    }
  }

  /**
   * Save the voice assistant state to Firestore
   * @param userId The user's unique ID
   * @param state The state to save
   */
  async saveAssistantState(userId: string, state: Partial<VoiceAssistantState>): Promise<void> {
    await this.ensureInitialized();
    if (!this.isInitialized) {
      logger.warn('Firebase not initialized - unable to save state');
      return;
    }

    try {      
      const userDocRef = doc(this.firestore, 'users', userId);
      
      // Prepare the data to save directly at root level, only include defined values
      const updateData: Record<string, any> = {
        lastUpdated: new Date().toISOString()
      };
      
      if (state.isEnabled !== undefined) {
        updateData['isEnabled'] = state.isEnabled;
      }

      if (state.hasHadSession !== undefined) {
        updateData['hasHadSession'] = state.hasHadSession;
      }
      
      // Update the user document with flattened data
      await setDoc(userDocRef, updateData, { merge: true });
      
      logger.log('Assistant state saved to Firebase for user:', userId);
    } catch (error) {
      logger.error('Error saving assistant state to Firebase:', error);
    }
  }

  /**
   * Get the voice assistant state from Firestore
   * @param userId The user's unique ID
   * @returns Partial state object from Firestore
   */
  async getAssistantState(userId: string): Promise<Partial<VoiceAssistantState>> {
    await this.ensureInitialized();
    if (!this.isInitialized) {
      logger.warn('Firebase not initialized - unable to get state');
      return {};
    }

    try {      
      const userDocRef = doc(this.firestore, 'users', userId);
      const userDoc = await getDoc(userDocRef);
      
      if (userDoc.exists()) {
        // Convert Firestore data to state format
        const result: Partial<VoiceAssistantState> = {
          userId: userId, // Always include the userId
          isEnabled: userDoc.data().isEnabled || false,
          hasHadSession: userDoc.data().hasHadSession || false
        };
        
        return result;
      }
      
      // Return an empty object with just the userId if no state exists
      return { userId };
    } catch (error) {
      logger.error('Error retrieving assistant state from Firebase:', error);
      return { userId };
    }
  }

  /**
   * Save the entire conversation history to Firestore
   * @param userId The user's unique ID
   * @param history Array of conversation messages
   */
  async saveConversationHistory(userId: string, history: ConversationMessage[]): Promise<void> {
    if (!this.isInitialized) {
      await this.ensureInitialized();
    }

    try {      
      const userDocRef = doc(this.firestore, 'users', userId);
      
      // Update the user document with the conversation history
      await setDoc(userDocRef, {
        conversationHistory: history,
        lastUpdated: new Date().toISOString()
      }, { merge: true });
      
      logger.log('Conversation history saved to Firebase for user:', userId);
    } catch (error) {
      logger.error('Error saving conversation history to Firebase:', error);
    }
  }

  /**
   * Get the conversation history from Firestore
   * @param userId The user's unique ID
   * @returns Array of conversation messages
   */
  async getConversationHistory(userId: string): Promise<ConversationMessage[]> {
    await this.ensureInitialized();
    if (!this.isInitialized) {
      logger.warn('Firebase not initialized - unable to get conversation history');
      return [];
    }

    try {
      const userDocRef = doc(this.firestore, 'users', userId);
      const userDoc = await getDoc(userDocRef);
      
      if (userDoc.exists() && userDoc.data().conversationHistory) {
        return userDoc.data().conversationHistory;
      }
      
      return [];
    } catch (error) {
      logger.error('Error retrieving conversation history from Firebase:', error);
      return [];
    }
  }

  /**
   * Add a message to the conversation history in Firestore
   * @param userId The user's unique ID
   * @param message The message to add
   */
  async addMessageToHistory(userId: string, message: ConversationMessage): Promise<void> {
    if (!this.isInitialized) {
      logger.warn('Firebase not initialized - unable to add message to history');
      return;
    }

    try {
      await this.ensureInitialized();
      
      const userDocRef = doc(this.firestore, 'users', userId);
      
      // Get current history
      const userDoc = await getDoc(userDocRef);
      let history: ConversationMessage[] = [];
      
      if (userDoc.exists() && userDoc.data().conversationHistory) {
        history = userDoc.data().conversationHistory;
      }
      
      // Add the new message
      history.push(message);
      
      // Update the document with the new history
      await setDoc(userDocRef, {
        conversationHistory: history,
        lastUpdated: new Date().toISOString()
      }, { merge: true });
      
      // Also log the individual message for analytics purposes
      await this.logConversationMessage(userId, message);
      
      logger.log('Message added to conversation history in Firebase for user:', userId);
    } catch (error) {
      logger.error('Error adding message to history in Firebase:', error);
    }
  }

  /**
   * Update the system message in the conversation history
   * @param userId The user's unique ID
   * @param content The new system message content
   */
  async updateSystemMessage(userId: string, content: string): Promise<void> {
    if (!this.isInitialized) {
      logger.warn('Firebase not initialized - unable to update system message');
      return;
    }

    try {
      await this.ensureInitialized();
      
      const userDocRef = doc(this.firestore, 'users', userId);
      
      // Get current history
      const userDoc = await getDoc(userDocRef);
      let history: ConversationMessage[] = [];
      
      if (userDoc.exists() && userDoc.data().conversationHistory) {
        history = userDoc.data().conversationHistory;
      }
      
      // Create a new system message
      const systemMessage: ConversationMessage = {
        role: 'system',
        content: content,
        timestamp: Date.now()
      };
      
      // Find the index of the first system message
      const systemMessageIndex = history.findIndex(msg => msg.role === 'system');
      
      if (systemMessageIndex >= 0) {
        // Update existing system message
        history[systemMessageIndex] = systemMessage;
      } else {
        // If no system message exists, add it at the beginning
        history.unshift(systemMessage);
      }
      
      // Update the document with the new history
      await setDoc(userDocRef, {
        conversationHistory: history,
        lastUpdated: new Date().toISOString()
      }, { merge: true });
      
      logger.log('System message updated in Firebase for user:', userId);
    } catch (error) {
      logger.error('Error updating system message in Firebase:', error);
    }
  }

  /**
   * Clear the conversation history in Firestore
   * @param userId The user's unique ID
   */
  async clearConversationHistory(userId: string): Promise<void> {
    if (!this.isInitialized) {
      logger.warn('Firebase not initialized - unable to clear conversation history');
      return;
    }

    try {
      await this.ensureInitialized();
      
      const userDocRef = doc(this.firestore, 'users', userId);
      
      // Update the user document with an empty conversation history
      await setDoc(userDocRef, {
        conversationHistory: [],
        lastUpdated: new Date().toISOString()
      }, { merge: true });
      
      logger.log('Conversation history cleared in Firebase for user:', userId);
    } catch (error) {
      logger.error('Error clearing conversation history in Firebase:', error);
    }
  }

  /**
   * Ensure Firebase is initialized - useful for lazy initialization
   */
  private async ensureInitialized(): Promise<void> {
    if (!this.isInitialized && this.config.firebaseConfig) {
      try {
        // Dynamic import of Firebase
        const firebase = await import('firebase/app');
        await import('firebase/firestore');
        await import('firebase/storage');
        
        if (!this.firebaseApp) {
          if (firebase.getApps().length === 0) {
            this.firebaseApp = firebase.initializeApp(this.config.firebaseConfig);
          } else {
            this.firebaseApp = firebase.getApp();
          }
          
          this.storage = getStorage(this.firebaseApp);
          this.firestore = getFirestore(this.firebaseApp);
          this.isInitialized = true;
        }
      } catch (error) {
        logger.error('Error initializing Firebase:', error);
        throw error;
      }
    }
  }

  /**
   * Store a past conversation in Firestore
   * @param userId The user's unique ID
   * @param conversation The conversation messages
   * @param sessionTime The session time
   */
  async storePastConversation(userId: string, conversation: ConversationMessage[], sessionTime: number): Promise<void> {
    if (!this.isInitialized) {
      logger.warn('Firebase not initialized - unable to store past conversation');
      return;
    }

    try {
      await this.ensureInitialized();
      
      const userDocRef = doc(this.firestore, 'users', userId);
      const userDoc = await getDoc(userDocRef);
      
      // Get existing past conversations or initialize as empty array
      const existingData = userDoc.exists() ? userDoc.data() : {};
      const pastConversations = existingData.pastConversationHistories || [];
      
      // Add new conversation with timestamp
      pastConversations.push({
        timestamp: sessionTime,
        messages: conversation
      });
      
      // Update the user document with the updated past conversations
      await setDoc(userDocRef, {
        pastConversationHistories: pastConversations,
        lastUpdated: new Date().toISOString()
      }, { merge: true });
      
      logger.log('Past conversation stored in Firebase for user:', userId);
    } catch (error) {
      logger.error('Error storing past conversation in Firebase:', error);
    }
  }

  /**
   * Retrieve past conversations from Firestore
   * @param userId The user's unique ID
   * @returns Array of past conversations
   */
  async getPastConversations(userId: string): Promise<Array<{timestamp: number, messages: ConversationMessage[]}>> {
    if (!this.isInitialized) {
      logger.warn('Firebase not initialized - unable to retrieve past conversations');
      return [];
    }

    try {
      await this.ensureInitialized();
      
      const userDocRef = doc(this.firestore, 'users', userId);
      const userDoc = await getDoc(userDocRef);
      
      if (userDoc.exists() && userDoc.data().pastConversationHistories) {
        const pastConversations = userDoc.data().pastConversationHistories;
        return pastConversations.map((conversation: any) => ({
          timestamp: conversation.timestamp,
          messages: conversation.messages
        }));
      }
      
      return [];
    } catch (error) {
      logger.error('Error retrieving past conversations from Firebase:', error);
      return [];
    }
  }

  /**
   * Store a past conversation summary in Firestore
   * @param userId The user's unique ID
   * @param summary The conversation summary
   * @param sessionTime The session time
   */
  async storePastConversationSummary(userId: string, summary: string, sessionTime: number): Promise<void> {
    if (!this.isInitialized) {
      logger.warn('Firebase not initialized - unable to store past conversation summary');
      return;
    }

    try {
      await this.ensureInitialized();
      
      const userDocRef = doc(this.firestore, 'users', userId);
      const userDoc = await getDoc(userDocRef);
      
      // Get existing past conversation summaries or initialize as empty array
      const existingData = userDoc.exists() ? userDoc.data() : {};
      const pastConversationSummaries = existingData.pastConversationSummaries || [];
      
      // Add new conversation summary with timestamp
      pastConversationSummaries.push({
        timestamp: sessionTime,
        summary: summary
      });
      
      // Update the user document with the updated past conversation summaries
      await setDoc(userDocRef, {
        pastConversationSummaries: pastConversationSummaries,
        lastUpdated: new Date().toISOString()
      }, { merge: true });
      
      logger.log('Past conversation summary stored in Firebase for user:', userId);
    } catch (error) {
      logger.error('Error storing past conversation summary in Firebase:', error);
    }
  }

  /**
   * Retrieve past conversation summaries from Firestore
   * @param userId The user's unique ID
   * @returns Array of past conversation summaries
   */
  async getPastConversationSummaries(userId: string): Promise<Array<{timestamp: number, summary: string}>> {
    if (!this.isInitialized) {
      logger.warn('Firebase not initialized - unable to retrieve past conversation summaries');
      return [];
    }

    try {
      await this.ensureInitialized();
      
      const userDocRef = doc(this.firestore, 'users', userId);
      const userDoc = await getDoc(userDocRef);
      
      if (userDoc.exists() && userDoc.data().pastConversationSummaries) {
        const pastConversationSummaries = userDoc.data().pastConversationSummaries;
        return pastConversationSummaries.map((summary: any) => ({
          timestamp: summary.timestamp,
          summary: summary.summary
        }));
      }
      
      return [];
    } catch (error) {
      logger.error('Error retrieving past conversation summaries from Firebase:', error);
      return [];
    }
  }
}

export default FirebaseService; 