/**
 * Voice Assistant Manager
 * Main controller that coordinates all voice assistant functionality
 */

import { IUIAdapter } from '../../ui/IUIAdapter';
import { IPlatformAdapter } from '../../platform/IPlatformAdapter';
import { IWebRTCManager } from './WebRTCManager';
import { IContentExtractor } from './ContentExtractor';
import { IAudioRecorder } from './AudioRecorder';
import { IStorageManager, ConversationMessage } from '../dal/StorageManager';
import { 
  VoiceAssistantConfig, 
  VoiceAssistantState, 
  PageContent,
  AssistantCommand,
  AudioRecording
} from '../../types';
import { defaultConfig } from '../../config/defaults';
import { IFirebaseService } from '../dal/FirebaseService';
import FirebaseService from '../dal/FirebaseService';
import { IOpenaiManager } from './OpenaiManager';
import logger from '../../utils/logger';

export interface IVoiceAssistantManager {
  init(): Promise<void>;
  toggle(): Promise<void>;
  startVoiceAssistant(): Promise<void>;
  stopVoiceAssistant(): Promise<void>;
  updateSystemPrompt(): Promise<string>;
  triggerAssistantResponse(message: string, isCommand: boolean, stopCurrentResponse: boolean): void;
  sendCommand(command: string): void;
  getConversationHistory(): Promise<ConversationMessage[]>;
  clearConversationHistory(): Promise<void>;
  dispose(): void;
  getCurrentSystemPrompt(): Promise<string>;
}

class VoiceAssistantManager implements IVoiceAssistantManager {
  // Dependencies
  private platform: IPlatformAdapter;
  private ui: IUIAdapter;
  private webrtc: IWebRTCManager;
  private contentExtractor: IContentExtractor;
  private storageManager: IStorageManager;
  private audioRecorder: IAudioRecorder;
  private openaiManager: IOpenaiManager | null = null;
  
  // Store any firebase service for direct access if needed
  private firebaseService: IFirebaseService | null = null;
  
  // Configuration
  private config: VoiceAssistantConfig;
  
  // State
  private state: VoiceAssistantState = {
    isEnabled: false,
    currentDomain: '',
    currentPath: '',
    hasHadSession: false
  };
  
  // Navigation cleanup
  private navigationCleanup: (() => void) | null = null;
  
  // In-progress response tracking
  private currentResponseText: string = '';
  private currentResponseItemId: string | null = null;
  
  // Audio recording state
  private isRecording: boolean = false;
  private currentRecording: AudioRecording | null = null;
  private lastTranscription: string = '';
  
  // Content change timeout
  private contentChangeTimeout: NodeJS.Timeout | null = null;
  
  /**
   * Create a new voice assistant
   * @param platform Platform adapter
   * @param ui UI adapter
   * @param webrtc WebRTC manager
   * @param contentExtractor Content extractor
   * @param storageManager Storage manager
   * @param config Configuration (optional)
   * @param audioRecorder Audio recorder (optional)
   * @param openaiManager OpenAI manager (optional)
   * @param firebaseService Firebase service (optional)
   */
  constructor(
    platform: IPlatformAdapter,
    ui: IUIAdapter,
    webrtc: IWebRTCManager,
    contentExtractor: IContentExtractor,
    storageManager: IStorageManager,
    config?: Partial<VoiceAssistantConfig>,
    audioRecorder?: IAudioRecorder,
    openaiManager?: IOpenaiManager,
    firebaseService?: IFirebaseService
  ) {
    this.platform = platform;
    this.ui = ui;
    this.webrtc = webrtc;
    this.contentExtractor = contentExtractor;
    this.storageManager = storageManager;
    this.config = { ...defaultConfig, ...config };
    this.audioRecorder = audioRecorder || null as unknown as IAudioRecorder; // Default to a no-op recorder if none provided
    this.openaiManager = openaiManager || null;
    this.firebaseService = firebaseService || null;
    
    // Set up WebRTC event handlers
    this.setupWebRTCHandlers();
  }
  
  /**
   * Initialize the voice assistant
   */
  async init(): Promise<void> {
    try {
      // Initialize platform adapter
      await this.platform.init();
      
      // Initialize UI
      await this.ui.init();
      
      // Set button image for Chrome adapter
      if ('getIconUrl' in this.platform) {
        const iconUrl = (this.platform as any).getIconUrl('icon.png');
        (this.ui as any).setButtonImageSrc?.(iconUrl);
      }
      
      // Position button
      (this.ui as any).positionButton?.({
        bottom: '20px',
        right: '20px'
      });
      
      // Register UI event handlers
      this.ui.onVoiceButtonClick(() => this.toggle());
      
      // Initialize Firebase if available to prevent race conditions
      if (this.firebaseService) {
        try {
          // Force Firebase initialization before loading state
          const userId = await this.storageManager.getUserId();
          await this.firebaseService.logUserSession(userId);
          console.log('Firebase initialized successfully in init()');
        } catch (error) {
          console.warn('Firebase initialization warning:', error);
        }
      }
      
      // Load saved state
      await this.loadState();
      
      // Start watching for navigation changes
      this.startNavigationWatching();
      
      // Update UI based on current state
      this.updateUI();
      
      const pageInfo = this.platform.getCurrentPageInfo();
      if (pageInfo) {
        this.state.currentDomain = pageInfo.domain;
        this.state.currentPath = pageInfo.path;
      }
        
      this.ui.showUI();
      
      // Only auto-start if enabled AND this is not the first startup
      if (this.state.isEnabled) {
        this.startVoiceAssistant();
      }
      
      // Add window beforeunload event to save recording when page is closed
      window.addEventListener('beforeunload', async (event) => {
        await this.stopAndSaveRecording();
      });
      
    } catch (error) {
      console.error('Error initializing voice assistant:', error);
    }
  }
  
  /**
   * Start the voice assistant
   */
  async startVoiceAssistant(): Promise<void> {   
    this.state.isEnabled = true;
    await this.saveState(); 

    this.updateUI();
    
    // Start watching for DOM changes
    this.contentExtractor.startWatchingDOMChanges(async (content) => {
      if (this.state.isEnabled && this.webrtc.getStatus().isConnected) {        
        // Clear any existing timeout
        if (this.contentChangeTimeout) {
          clearTimeout(this.contentChangeTimeout);
          this.contentChangeTimeout = null;
        }
        
        // Set a new timeout to check if help is needed after 15 seconds of inactivity
        this.contentChangeTimeout = setTimeout(async () => {
          // Only ask if help is needed if we're still enabled and connected
          if (this.state.isEnabled && this.webrtc.getStatus().isConnected) {
            const helpMessage = `${AssistantCommand.USER_IDLE}\nCurrent page content:\nTitle: ${content.title}\nHeadings: ${content.headings.join(', ')}\nMain content: ${content.mainContent}`;
            let currentSystemPrompt = await this.getCurrentSystemPrompt();
            currentSystemPrompt += `\nCommand was sent: ${helpMessage}`;
            
            this.triggerAssistantResponse(currentSystemPrompt, true, true);
          }
          
          this.contentChangeTimeout = null;
        }, 15000); // 15 seconds delay
      }
    });
    
    // Connect to WebRTC if not already connected
    if (!this.webrtc.getStatus().isConnected && !this.webrtc.getStatus().isConnecting) {
      await this.webrtc.connect();
    }
  }
  
  /**
   * Stop the voice assistant
   */
  async stopVoiceAssistant(): Promise<void> {
    this.state.isEnabled = false;    // Set the conversation started flag
    this.state.hasHadSession = true;

    this.updateUI();
    
    // Clear any pending content change timeout
    if (this.contentChangeTimeout) {
      clearTimeout(this.contentChangeTimeout);
      this.contentChangeTimeout = null;
    }
    
    // Stop DOM observation
    this.contentExtractor.stopWatchingDOMChanges();
    
    // Stop any active recording
    await this.stopAndSaveRecording();
    
    // Reset reconnection attempts
    this.webrtc.resetReconnectAttempts();
    
    // Disconnect WebRTC
    this.webrtc.disconnect();
    
    // Clear conversation history
    await this.clearConversationHistory();
    
    // Save state
    await this.saveState();
  }
  
  /**
   * Toggle the voice assistant on/off
   */
  async toggle(): Promise<void> {
    if (this.state.isEnabled) {
      await this.stopVoiceAssistant();
    } else {
      await this.startVoiceAssistant();
    }
  }
  
  /**
   * Get the conversation history
   */
  async getConversationHistory(): Promise<ConversationMessage[]> {
    return this.storageManager.getConversationHistory();
  }
  
  /**
   * Clear the conversation history
   */
  async clearConversationHistory(): Promise<void> {
    if (this.openaiManager) {
      // If OpenAI manager is available, use it to generate a summary before clearing
      await this.storageManager.clearConversationHistory(async (messages) => {
        const result = await this.openaiManager!.summarizeConversation(messages);
        return result.summary;
      });
    } else {
      // Otherwise just clear without summarizing
      await this.storageManager.clearConversationHistory();
    }
  }
  
  /**
   * Set up WebRTC event handlers
   */
  private setupWebRTCHandlers(): void {
    this.webrtc
      .on('onConnecting', () => {
        this.updateUI();
      })
      .on('onConnected', async (remoteStream) => {
        this.updateUI();
        
        // Get audio analyzer from WebRTC and pass it to UI
        const analyzer = this.webrtc.getAudioAnalyser();
        if (analyzer) {
          this.ui.setAudioAnalyser(analyzer);
        }
        
        // Add remote audio to the audio element
        const audioElement = this.ui.getAudioElement();
        audioElement.srcObject = remoteStream;
        audioElement.play().catch(e => console.error('Error playing audio:', e));
        
        // Start recording if enabled
        if (this.config.settings.recordConversations) {
          await this.startRecording();
        }
        if (!this.state.hasHadSession) {
          await this.sendCommand(AssistantCommand.USER_CONNECTED); 
        } else {
          await this.sendCommand(AssistantCommand.PAGE_CHANGED);
        }
      })
      .on('onDisconnected', async () => {
        this.updateUI();
        
        // Stop recording if it's in progress
        await this.stopAndSaveRecording();
        
        // Try to reconnect if enabled
        if (this.state.isEnabled && this.webrtc.getReconnectAttempts() < 3) {
          setTimeout(() => {
            if (this.state.isEnabled && 
                !this.webrtc.getStatus().isConnected && 
                !this.webrtc.getStatus().isConnecting) {
              this.webrtc.connect();
            }
          }, Math.min(3000 * this.webrtc.getReconnectAttempts(), 9000));
        } else if (this.webrtc.getReconnectAttempts() >= 3) {
          this.ui.showNotification('Connection issues. Please try toggling the assistant off and on again.');
        }
      })
      .on('onError', (error) => {
        console.error('WebRTC error:', error);
        
        // Show visual notification
        let errorMessage = this.config.prompts.connectionError;
        if (error.message) {
          if (error.message.includes('microphone')) {
            errorMessage = "Microphone access denied. Please check permissions.";
          } else if (error.message.includes('Authorization')) {
            errorMessage = "API key error. Please check your OpenAI API key.";
          }
        }
        
      })
      .on('onMessage', this._handleWebRTCMessage.bind(this));
  }
  
  /**
   * Handle WebRTC messages from OpenAI
   * Centralized handler for all message types
   * @param message The message from OpenAI
   */
  private _handleWebRTCMessage(message: any): void {
    // Group 1: Error handling
    if (message.type === "error" && message.error) {
      console.error('OpenAI API error:', message.error);
      return;
    }
    
    // Group 2: Session setup and management
    if (message.type === "session.created" || message.type === "session.updated") {
      return;
    }
    
    if (message.type === "conversation.created") {
      return;
    }
    
    // Group 3: User input handling
    if (message.type === "input_audio_buffer.speech_started") {
      this.ui.updateAISpeakingState(false);
      return;
    }
    
    if (message.type === "input_audio_buffer.speech_stopped") {
      return;
    }
    
    // AI speech events
    if (message.type === "output_audio_buffer.started") {
      console.log('AI speech started');
      this.ui.updateAISpeakingState(true);
      return;
    }
    
    if (message.type === "output_audio_buffer.stopped") {
      console.log('AI speech stopped');
      this.ui.updateAISpeakingState(false);
      return;
    }
    
    if (message.type === "conversation.item.input_audio_transcription.completed" && message.transcript) {
      console.log('User transcription completed:', message.transcript);
      this.logMessage('user', message.transcript);
      
      // Store the transcription for any active recording
      if (this.isRecording && this.currentRecording) {
        this.lastTranscription += `User: ${message.transcript}\n`;
      }
      
      return;
    }
    
    if (message.type === "conversation.item.input_audio_transcription.failed") {
      return;
    }
    
    // Group 4: Response preparation and streaming
    if (message.type === "response.created") {
      return;
    }
    
    if (message.type === "response.text.delta" && message.delta) {
      // Collect text deltas for in-progress responses
      this.addToCurrentResponse(message.delta, message.item_id);
      return;
    }
    
    // Group 5: Response completion
    if (message.type === "response.text.done" && message.text) {
      // Use the complete text from the done event
      this.logMessage('assistant', message.text);
      
      // Store the transcription for any active recording
      if (this.isRecording && this.currentRecording) {
        this.lastTranscription += `Assistant: ${message.text}\n`;
      }
      
      // Reset current response tracking
      if (this.currentResponseItemId === message.item_id) {
        this.resetCurrentResponse();
      }
      return;
    }
    
    if (message.type === "response.audio.done") {
      return;
    }
    
    // Main completion event with potential transcript
    if (message.type === "response.done") {
      try {
        // Extract transcript using the correct path from the provided structure
        if (message.response && 
            message.response.output && 
            Array.isArray(message.response.output) && 
            message.response.output.length > 0 &&
            message.response.output[0].content && 
            Array.isArray(message.response.output[0].content) && 
            message.response.output[0].content.length > 0 &&
            message.response.output[0].content[0].transcript) {
          
          const transcript = message.response.output[0].content[0].transcript;
          
          // Only log if we haven't already logged from text.done
          if (this.currentResponseItemId !== message.response.id) {
            this.logMessage('assistant', transcript);
          }
        } 
        // Fallback for text-only responses
        else if (message.response && message.response.text && 
                 !message.response.text.trim().startsWith("{") &&
                 this.currentResponseItemId !== message.response.id) {
          console.log('Complete response text:', message.response.text);
          this.logMessage('assistant', message.response.text);
        }
      } catch (error) {
      }
      
      // Always reset current response when complete
      this.resetCurrentResponse();
      return;
    }
    
    // Group 6: Function calls
    if (message.type === "response.function_call_arguments.done" && message.arguments && message.call_id) {
      console.log(`Function call: ${message.call_id}`, message.arguments);
      // Handle function calls here
      return;
    }
  }
  
  /**
   * Log a message to conversation history
   * @param role Message role ('user' or 'assistant')
   * @param content Message content
   */
  private async logMessage(role: 'user' | 'assistant', content: string): Promise<void> {
    if (!content || content.trim() === '') return;
    
    const message: ConversationMessage = {
      role: role,
      content: content,
      timestamp: Date.now()
    };
    
    await this.storageManager.addMessageToHistory(message);
    
    // Note: No need to send history to OpenAI - the Realtime API
    // maintains conversation context through individual messages
    console.log(`${role.charAt(0).toUpperCase() + role.slice(1)} message logged to local history`, content);
  }
  
  /**
   * Update the UI based on current state
   */
  private updateUI(): void {
    this.ui.updateStatus({
      isConnected: this.webrtc.getStatus().isConnected,
      isConnecting: this.webrtc.getStatus().isConnecting,
    });
  }
  
  /**
   * Start watching for navigation changes
   */
  private startNavigationWatching(): void {
    // Clean up previous watcher if exists
    if (this.navigationCleanup) {
      this.navigationCleanup();
      this.navigationCleanup = null;
    }
    
    // Start new watcher
    this.navigationCleanup = this.platform.watchNavigation((url) => {
      // Handle navigation
      try {
        const urlObj = new URL(url);
        const domain = urlObj.hostname.replace('www.', '');
        const path = urlObj.pathname;
        
        // Check if path changed
        if (path !== this.state.currentPath) {
          this.handlePathChange(domain, path);
        }
      } catch (error) {
        console.error('Error handling navigation:', error);
      }
    });
  }
  
  /**
   * Handle path change
   * @param domain New domain
   * @param path New path
   */
  private async handlePathChange(domain: string, path: string): Promise<void> {
    // Save any active recording before changing domains/paths
    if (this.isRecording) {
      await this.stopAndSaveRecording();
    }
    
    // Update state
    this.state.currentDomain = domain;
    this.state.currentPath = path;
    
    // Update system prompt if connected
    if (this.state.isEnabled && this.webrtc.getStatus().isConnected) {
      // When navigation occurs, we'll wait a short moment for any immediate DOM updates
      // that typically happen during navigation
      setTimeout(async () => {
        await this.sendCommand(AssistantCommand.PAGE_CHANGED);
      }, 100); // Short delay to allow for immediate DOM updates
    }
  }
  
  /**
   * Update the system prompt with current context
   * @returns Promise with the formatted system instructions
   */
  async updateSystemPrompt(): Promise<string> {
    // Extract page content
    const pageContent = this.contentExtractor.extractPageContent();
    
    // Format path for display
    let pathDisplay = this.state.currentPath;
    if (pathDisplay === '/' || pathDisplay === '') {
      pathDisplay = 'home';
    } else {
      // Clean up the path to make it more user-friendly
      pathDisplay = pathDisplay.replace(/^\/|\/$/g, ''); // Remove leading/trailing slashes
      pathDisplay = pathDisplay.replace(/-/g, ' '); // Replace dashes with spaces
    }
    // Get page-specific instructions if available
    let pageSpecificInstructions = '';
    
    if (this.config.pageSpecificInstructions && 
        this.config.pageSpecificInstructions[this.state.currentDomain]) {
      
      const domainInstructions = this.config.pageSpecificInstructions[this.state.currentDomain];
      
      if (domainInstructions[this.state.currentPath]) {
        pageSpecificInstructions = domainInstructions[this.state.currentPath];
      } else {
        // Check for partial path matches
        const pathKeys = Object.keys(domainInstructions);
        for (const pathKey of pathKeys) {
          if (this.state.currentPath.startsWith(pathKey)) {
            pageSpecificInstructions = domainInstructions[pathKey];
            break;
          }
        }
      }
    }
    
    // Get conversation history
    const conversationHistory = await this.storageManager.getConversationHistory();
    
    // Format conversation history for inclusion in the prompt
    let conversationHistoryText = '';
    if (conversationHistory && conversationHistory.length > 0) {
      // Only include the last 10 messages to avoid making the prompt too long
      const recentMessages = conversationHistory.slice(-10);
      
      conversationHistoryText = '\n';
      recentMessages.forEach((msg, index) => {
        const role = msg.role === 'user' ? 'User' : 'Assistant';
        // Add a separator between conversation turns for better readability
        if (index > 0 && recentMessages[index-1].role !== msg.role) {
          conversationHistoryText += '\n';
        }
        conversationHistoryText += `${role}: ${msg.content}\n`;
      });
    }
    
    // Get past conversation summaries
    let pastConversationSummariesText = '';
    try {
      const pastSummaries = await this.storageManager.getPastConversationSummaries();
      if (pastSummaries && pastSummaries.length > 0) {
        // Sort by timestamp (newest first)
        const sortedSummaries = pastSummaries.sort((a, b) => b.timestamp - a.timestamp);
        // Take only the last 5 summaries
        const recentSummaries = sortedSummaries.slice(0, 5);
        
        recentSummaries.forEach((summary, index) => {
          const formattedDate = new Date(summary.timestamp).toLocaleString();
          pastConversationSummariesText += `Conversation ${index + 1} (${formattedDate}):\n${summary.summary}\n\n`;
        });
      }
      
      if (!pastConversationSummariesText) {
        pastConversationSummariesText = "No previous conversations available.";
      }
    } catch (error) {
      console.error('Error retrieving past conversation summaries:', error);
      pastConversationSummariesText = "Error retrieving past conversation summaries.";
    }
    
    // Format system instructions
    const instructions = this.config.prompts.systemInstructions
      .replace(/\{\{DOMAIN\}\}/g, this.state.currentDomain)
      .replace(/\{\{PATH\}\}/g, pathDisplay)
      .replace('{{PAGE_CONTENT}}', JSON.stringify(pageContent))
      .replace('{{CONVERSATION_STARTED_BEFORE}}', String(this.state.hasHadSession))
      .replace('{{PAST_CONVERSATION_SUMMARIES}}', pastConversationSummariesText)
      //.replace('{{PAGE_SPECIFIC_INSTRUCTIONS}}', pageSpecificInstructions || '')
      //.replace('{{CONVERSATION_HISTORY}}', conversationHistoryText)
    
    // Update system message in conversation history (local storage only)
    await this.storageManager.updateSystemMessage(instructions);
    
    // Send instructions to WebRTC manager
    if (this.webrtc.updateInstructions(instructions)) {
      console.log('System instructions sent to OpenAI session with conversation history');
    } else {
      console.log('Failed to send system instructions - no active connection');
    }

    return instructions;
  }

  /**
   * Get the current system prompt
   * @returns Promise with the current system prompt
   */
  async getCurrentSystemPrompt(): Promise<string> {
    return this.updateSystemPrompt();
  }
  
  /**
   * Stop the current response and audio playback
   */
  private stopCurrentResponse(): void {
    try {
      // Reset WebRTC response tracking first
      this.resetCurrentResponse();

      // Tell WebRTC to stop current response - this may fail if there's no active response
      try {
        this.webrtc.stopCurrentResponse();
      } catch (error) {
        console.log('WebRTC stop response failed, continuing with audio cleanup:', error);
      }
      
      // Ensure UI animation stops when response is interrupted
      this.ui.updateAISpeakingState(false);

    } catch (error) {
      console.error('Error in stopCurrentResponse:', error);
    }
  }

  /**
   * Trigger the assistant to speak a specific message or process a command
   * @param message Message or command for the assistant to process
   * @param isCommand Whether this is a command message
   */
  triggerAssistantResponse(message: string, isCommand: boolean = false, stopCurrentResponse: boolean = false): void {
    try {
      // Stop any current response first
      if (stopCurrentResponse) {
        this.stopCurrentResponse();
      }

      // Log the message to conversation history
      
      // Try to use WebRTC for the response
      if (!this.webrtc.triggerAssistantResponse(message)) {
        logger.log('Failed to trigger assistant response:', message);
      }
    } catch (error) {
      logger.error('Error triggering assistant response:', error);
    }
  }
  
  /**
   * Load saved state
   */
  private async loadState(): Promise<void> {
    try {
      // Load state from storage
      const savedState = await this.storageManager.getAssistantState();
      this.state.isEnabled = savedState.isEnabled || this.config.settings.voiceEnabledDefault;

      if (savedState.hasHadSession !== undefined) {
        this.state.hasHadSession = savedState.hasHadSession;
      }

      // Set audio volume
      (this.ui as any).setVolume?.(this.config.settings.voiceVolume);

    } catch (error) {
      console.error('Error loading state:', error);
    }
  }
  
  /**
   * Save current state to storage
   */
  private async saveState(): Promise<void> {
    try {
      console.log('Saving state:', this.state);
      // only write hasHadSession if available
      if (this.state.hasHadSession !== undefined) {
        await this.storageManager.saveAssistantState({
          hasHadSession: this.state.hasHadSession
        });
      } 

      if (this.state.isEnabled !== undefined) {
        await this.storageManager.saveAssistantState({
          isEnabled: this.state.isEnabled
        });
      }
    } catch (error) {
      console.error('Error saving state:', error);
    }
  }
  
  /**
   * Clean up resources
   */
  async dispose(): Promise<void> {
    // Clean up all resources
    await this.stopVoiceAssistant();
    
    // Clear any pending content change timeout
    if (this.contentChangeTimeout) {
      clearTimeout(this.contentChangeTimeout);
      this.contentChangeTimeout = null;
    }
    
    if (this.navigationCleanup) {
      this.navigationCleanup();
      this.navigationCleanup = null;
    }
    
    // Stop DOM change watching
    this.contentExtractor.stopWatchingDOMChanges();
    
    // Stop any active recording
    await this.stopAndSaveRecording();
    
    
    // Make sure AI speaking state is reset in UI
    this.ui.updateAISpeakingState(false);
    
    // Disconnect WebRTC
    if (this.webrtc.getStatus().isConnected) {
      this.webrtc.disconnect();
    }
    
    // Hide UI
    this.ui.hideUI();
  }
  
  /**
   * Reset the current response tracking
   */
  private resetCurrentResponse(): void {
    this.currentResponseText = '';
    this.currentResponseItemId = null;
  }
  
  /**
   * Add a text delta to the current response
   * @param delta Text delta to add
   * @param itemId ID of the response item
   */
  private addToCurrentResponse(delta: string, itemId: string): void {
    // Reset if this is a new item
    if (this.currentResponseItemId !== itemId) {
      this.resetCurrentResponse();
      this.currentResponseItemId = itemId;
    }
    
    // Add the delta
    this.currentResponseText += delta;
  }
  
  /**
   * Send a command to the assistant
   * @param command Command to send (should be uppercase)
   */
  async sendCommand(command: string): Promise<void> {
    // Ensure command is uppercase
    const formattedCommand = command.toUpperCase();

    let currentSystemPrompt = await this.getCurrentSystemPrompt();
    currentSystemPrompt += `\nCommand was sent: ${formattedCommand}`;
    
    // Only send if enabled and connected
    if (this.state.isEnabled && this.webrtc.getStatus().isConnected) {
      this.triggerAssistantResponse(currentSystemPrompt, true, true);
    } else {
      console.log(`Command ${formattedCommand} not sent - assistant is disabled or disconnected`);
    }
  }
  
  /**
   * Start recording the conversation
   */
  private async startRecording(): Promise<void> {
    try {
      if (this.isRecording || !this.audioRecorder) {
        console.warn('Recording not possible: ' + (!this.audioRecorder ? 'No recorder available' : 'Already recording'));
        return;
      }
      
      // Get local and remote streams
      const localStream = this.webrtc.getLocalStream();
      const remoteStream = this.webrtc.getRemoteStream();
      
      if (!localStream || !remoteStream) {
        console.warn('Cannot start recording: streams not available');
        return;
      }
      
      // Create a mixed stream with both local and remote audio
      const mixedStream = this.createMixedStream(localStream, remoteStream);
      
      // Get user ID
      const userId = await this.storageManager.getUserId();
      
      // Start recording with the mixed stream
      this.audioRecorder.startRecording(userId, mixedStream);
      this.isRecording = true;
      this.lastTranscription = '';
      
      console.log('Started recording conversation');
    } catch (error) {
      console.error('Error starting recording:', error);
      this.isRecording = false;
    }
  }
  
  /**
   * Stop recording and save to cloud storage
   */
  private async stopAndSaveRecording(): Promise<void> {
    if (!this.isRecording || !this.audioRecorder) return;
    this.state.hasHadSession = true;
    await this.saveState();
    try {
      // Stop the recording
      const recording = await this.audioRecorder.stopRecording();
      this.isRecording = false;
      
      if (!recording) {
        console.warn('No recording to save');
        return;
      }
      
      this.currentRecording = recording;
      
      // If firebase service is available and recording has content, upload it asynchronously
      if (this.config.firebaseConfig && recording.audioBlobs.length > 0) {
        const firebaseService = (this.storageManager as any).firebaseService;
        if (firebaseService) {
          // Don't await - upload in the background
          this.uploadRecordingAsync(firebaseService, recording, this.lastTranscription);
        }
      }
    } catch (error) {
      console.error('Error stopping and saving recording:', error);
    }
  }
  
  /**
   * Upload recording asynchronously to avoid freezing the UI
   */
  private uploadRecordingAsync(
    firebaseService: any, 
    recording: AudioRecording, 
    transcript: string
  ): void {
    // Run the upload in the background
    (async () => {
      try {
        const url = await firebaseService.uploadAudioRecording(recording, transcript);
        console.log('Audio recording uploaded to:', url);
      } catch (uploadError) {
        console.error('Error uploading recording:', uploadError);
      }
    })();
  }
  
  /**
   * Create a mixed audio stream from local and remote streams
   * @param localStream The local stream
   * @param remoteStream The remote stream
   * @returns A new MediaStream containing both audio tracks
   */
  private createMixedStream(localStream: MediaStream, remoteStream: MediaStream): MediaStream {
    const ctx = new AudioContext();
    const destination = ctx.createMediaStreamDestination();
    
    // Add local audio track to the mix
    if (localStream.getAudioTracks().length > 0) {
      const localSource = ctx.createMediaStreamSource(localStream);
      localSource.connect(destination);
    }
    
    // Add remote audio track to the mix
    if (remoteStream.getAudioTracks().length > 0) {
      const remoteSource = ctx.createMediaStreamSource(remoteStream);
      remoteSource.connect(destination);
    }
    
    return destination.stream;
  }
}

export default VoiceAssistantManager; 